@use "../../scss-fragments/global";
@use "../../scss-fragments/forms";


.p-grey {
    color: global.$mid-grey-1;
}

.tickimg {
    display: block;
    margin: 45px auto 30px auto;
}

.contentholder {
    text-align: center;
}

form > #email {
    margin-bottom:20px;
}
.submit-btn {
    margin-top:50px;
}

.spacer-small {
    height:30px;
}
.spacer-large {
    height:120px;
}