@use "../../scss-fragments/global";

.users-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .scroll-container {
        height: calc(100vh - 30rem) !important;
    }
}

.change-user-panel-content {
    .submit-btn {
        margin-top: 1rem !important;
        margin-bottom: 0 !important;
    }
}

.user-campaigns-popup {
    /* width: fit-content; */
    max-width: 1200px;
    max-height: 90vh;

    .scroll-area {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
        margin: 0;
        padding: 0 1rem 1rem;;

        width: 100%;
        max-height: 60vh;
        overflow-y: auto;
    }
}

.users-panel {
    @include global.panel-style;
    @include global.panel-shadow;
    margin: 0 0 1rem;

    position: relative;

    width: 100%;
    max-height: calc(100% - 6rem);
    flex-grow: 1;

    color: global.$label-color;
    font-weight: 500;

    .add-btn {
        position: absolute;
        left: 2.4rem;
        top: 1.5rem;
        z-index: 1000;
    
        display: flex;
        align-items: center;
        justify-content: center;
    
        width: 2rem;
        height: 2rem;
        border-radius:100em;
        border-width: 2px;
        border-style: solid;
        border-color: global.$twitch-purple;
        color: global.$twitch-purple;
    
        cursor: pointer;
    }

    .scroll-container {
        width: 100%;
        height: 100%;
        overflow-y: scroll;

        &::-webkit-scrollbar-track {
            margin-top: 3rem;
        }
    }

    & a {
        color: global.$primary-color;

        &:visited {
            color: global.$primary-color;
        }

        &:hover {
            color: global.$primary-color;
        }

        &:active {
            color: global.$primary-color;
        }
    }

    .grid {
        display: grid;

        grid-template-columns: auto auto auto auto auto auto auto auto;
        gap: 0.5rem;

        align-items: center;

        .header {
            @include global.unselectable;

            position: sticky;
            top: 0;
            background-color: global.$panel-body-bg;
            padding-bottom: 1rem;
            width: 100%;
            z-index: 999;
        }

        .pp-style {
            border-color: global.$panel-body-bg;
        }

        .email {
            font-weight: 600;
        }

        .campaigns {
            font-weight: 600;
            color: global.$primary-color;
        }

        .icons {
            color: global.$primary-color;
            margin: 0.2rem 0.75rem;
            cursor: pointer;
        }

        hr {
            position: absolute;
            left: 1.5rem;
            top: 3rem;
            width: calc(100% - 4.5rem);
            z-index: 1000;
        }

        .full-width {
            grid-column-start: 1;
            grid-column-end: 9;
        }

        .centered {
            justify-self: center;
            text-align: center;
        }
    }
}
