@use '../../scss-fragments/global';

.chat-toggle-button {
    @include global.round-button-style;
    @include global.standard-rollover-transition;

    position: fixed;
    right: 1rem;
    bottom: 0.5rem;

    width: 4rem;
    height: 4rem;

    border-width: 0;
    background-color: global.$dark-grey-3;
    border-color: global.$dark-grey-3;
    color: global.$mid-grey-2;

    opacity: 0.5;

    &:hover {
        opacity: 1;
    }
}
.chatHistory {
    display: flex;
    align-items: center;
    gap: 2rem;
    border-bottom: solid 1px  global.$mid-grey-2;
    padding-bottom:10px;
    margin-bottom: 5px;
}
.chat-transcript {
    @include global.panel-style;
    @include global.panel-shadow;
    margin: 0;

    position: fixed;
    right: 5.5rem;
    bottom: 0.5rem;

    width: 40vw;
    min-width: 300px;
    height: 60vh;
    min-height: 500px;

    transform: scale(0);
    transform-origin: bottom right;
    transition: transform 0.3s;
.titles{
    border-bottom: solid 1px  global.$mid-grey-2;
}
    .chat{
       
    }
    p {
        
        margin-top: 0;
        margin-bottom: 0.5rem;
    }
    .channel-title{
        overflow: hidden;
        min-width:25%;
        max-width:25%;
    }
    .channel{
        overflow: hidden;
        min-width:25%;
        max-width:25%;
        font-weight: 500;
        border-radius: 50em;
        border: 2px solid global.$twitch-purple;
        color: global.$twitch-purple;
        padding:5px 10px 5px 5px;
        font-size:.8rem;
        margin-bottom:5px;
    }
    .name {
       
        font-weight: 700;
    }
    .message {
        width:50%;
    }

    .hash-hilight {
        background-color: global.$success-bg-color;
    }

    .scroll-area {
        width: calc(40vw - 3rem);
        min-width: 160px;
        height: calc(60vh - 9rem);
        min-height: 410px;
        margin: 10px 0 -60px 0 ;
        padding-right: 0.5rem;
        overflow-y: auto;
    }
}

.ama {
    font-weight:bold;
    background-color: cornflowerblue;
    color:white;
    padding:3px;
    margin-top:2px;
    cursor: pointer;
}
.ama:hover {
    text-decoration: underline;
}

.ct-open {
    transform: scale(1);
}
