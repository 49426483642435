@use '../../scss-fragments/global';

.preview-frame-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0 1.5rem;
}

.preview-mode-frame {
    position: relative;

    width: 426px;
    height: 240px;
    margin: 0;
    padding: 0;
    border: none;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    pointer-events: none;

    iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.close-diagnotics-button {
    color: global.$error-dark-color !important;
    cursor: pointer;
}

.preview-random-1 {
    background-image: url(../../assets/_streamerExampleBk1.jpg);
}
.preview-random-2 {
    background-image: url(../../assets/_streamerExampleBk.jpg);
}
.preview-title-holder {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}

.preview-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.preview-loading {
    position: absolute;
    left: 0;
    top: 0;


    -moz-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    -o-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(100%);
    filter: gray;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");



    width: 100%;
    height: 100%;


    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.frame-maximized {
    width: calc(100vw - 47rem);
    height: calc((100vw - 47rem) * 0.5625);
}

.controls-area {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 11rem);
    min-height: calc(100vh - 11rem);
    max-height: calc(100vh - 11rem);
    overflow-y: scroll;
    padding-right: 1rem;
    gap: 2rem !important;

    .live-control-group-holder {
        min-width: 38rem !important;
        max-width: 38rem !important;
    }
}

.preview-mode-frame-holder {
    @include global.standard-rollover-transition;
    position: fixed;
    z-index: 999;
    padding: 0;
    max-width: calc(426px + 3rem + 4px);
}

.preview-maximized {
    width: calc(100vw - 2rem);
    height: calc(100vh - 2rem);
    min-width: calc(100vw - 2rem);
    min-height: calc(100vh - 2rem);
    max-width: calc(100vw - 2rem);
    max-height: calc(100vh - 2rem);
    left: 1rem;
    top: 1rem;
}

.win-controls {
    cursor: pointer;
}

.preview-diagnostics {
    position: absolute;
    z-index: 10000 !important;
    pointer-events: all;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 0.5rem;
    font-size: 0.8rem;
    z-index: 999;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    width: 300px;
    max-width: 50%;
    max-height: 100%;
    overflow-y: auto;

    h3 {
        color: white;
        font-size: 1.2rem;
        cursor: pointer;
    }

    .slim-button {
        padding: 0.5rem 1rem;
        height: 2.5rem;
        margin: 0;
    }

    .diagnostic-holder {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        font-weight: 500;
        justify-items: center;
        align-items: center;

        width: 100%;
    }

    .loading {
        color: global.$warning-dark-color;
    }
    .loaded {
        color: global.$success-dark-color;
    }
    .cancelled {
        color: global.$error-dark-color;
    }
}

.diagnostics-collapsed {
    overflow-y: hidden;
    overflow-x: hidden;
}
