@use "../../scss-fragments/global";
@use "../../scss-fragments/forms";

.login-vid {
  position: fixed;
  top:0;
  left:0;
  min-width: 100%;
  min-height: 100%;
  z-index:-1;
}


.login-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding:auto 0;
  width:100%;
  //background-color: rgba(0,0,0,.8);
  background-image: linear-gradient(to bottom right, rgba(145,70,255,.8), rgba(0,200,175,.8));

  img{

    width:400px;
    margin-left:-150px;
    margin-right:150px;
    margin-top:-100px;
  }
}

.login-form-holder {
  width:450px;
  max-width: 450px;
  padding: 0;
}

.login-panel-min-height {
  min-height: 680px;
}

.login-twitch-logo {
  width: 160px;
  margin: 0.5rem;
}

.login-icast-logo {
  width: 160px;
  margin: 0.5rem;
}

.register-link {
  color: global.$dark-grey-1;
  cursor: pointer;
  text-decoration: underline;
}
.forgotten-password-link {
  color: global.$primary-color;
  cursor: pointer;
  text-decoration: underline;
}
