@use "../../../scss-fragments/global";
@use "../../../scss-fragments/forms";

.image-upload-holder-2 {
    /*width: 100%;*/
    display: flex;
    flex-direction: column;
    margin: 0; /*0 2rem;*/
    padding: 0;

    .content {
        width: 100%;
        display: flex;
        gap: 1rem;
    }

    .thumb-left {
        flex-direction: row;
    }
    
    .thumb-top {
        flex-direction: column;
        gap: 0.5rem;
    }


    .thumb-holder {
        position: relative;
        margin: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        text-align: center;
    }

    .thumb-img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .file-holder {
        border: 2px solid  global.$primary-light-color;
        border-radius: global.$button-border-radius;
        background-color: #FFFFFF;
        color: #82E9FF;
        font-weight: 500;

        display: flex;
        align-items: center;
        justify-content: center;

        text-align: center;
    }

    .options-holder {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        gap: 1rem;

        .option {
            @include global.unselectable;
            width: 3rem;
            height: 3rem;
            
            display: flex;
            align-items: center;
            justify-content: center;

            color: global.$primary-color;
        }

        .opt-off {
            color: global.$light-text-color;
        }
    }

    .v-opt {
        flex-direction: column;
    }

    .leftAlign {
        justify-content:left 
    }
}
