@use "../../scss-fragments/global";

.close-btn {
    @include global.round-button-style;
    background-color: global.$off-white;
    
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
}
