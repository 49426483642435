@use "../../scss-fragments/global";

.csv-preview-panel {
    max-width: 90vw !important;
}

.csv-preview {
    width: 100%;
    height: 80vh;

    display: flex;
    flex-direction: column;

    .standard-button {
        margin: 0 !important;
    }

    .controls-column {
        align-self: center;
    }

    .image-holder {
        width: 100%;
        text-align: center;
        img {
            max-width: 50px;
            max-height: 50px;
        }
    }

    .questions-holder {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow-y: scroll;
        overflow-x: auto;
        gap: 0.5rem;
        padding-right: 0.5rem;
        margin-bottom: 1rem;

        .question-row {
            display: flex;
            flex-direction: row;
            height: fit-content;
            gap: 1rem;
            padding: 0 0.5rem;
    
            .include-question {
                width: 3rem;
                align-self: center;
            }
            .question {
                width: 30%;
                font-weight: 500;
            }
            .answer {
                width: 20%;
                padding: 0.5rem;
            }
            .correct-answer {
                background-color: global.$success-color;
                font-weight: 500;
            }
            .correct-answer-light {
                background-color: global.$success-bg-color;
                font-weight: 400;
            }
        }

        .odd-row {
            background-color: global.$primary-light-color;
            padding: 0.5rem 0.5rem;
        }
    }
}
