@use "global";

@mixin form-field-holder {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
}

@mixin form-field-style {
    border-radius: global.$button-border-radius;
    border-width: 2px;
    border-style: solid;
    border-color: global.$mid-grey-2;
    background-color: global.$mid-grey-2;
    margin: 0;
    height: 3.5rem;
    padding: 0 1rem;
}

@mixin text-area-style {
    border-radius: global.$button-border-radius;
    border-width: 2px;
    border-style: solid;
    border-color: global.$mid-grey-2;
    background-color: global.$mid-grey-2;
    margin: 0;
    padding: 1rem;
}

.form-holder {
    .form {
        margin-top: 0;
    }

    .title-text {
        color: global.$label-color;
        font-weight: 500;
        margin-bottom: 2rem;
    }

    .submit-btn {
        @include global.button-style;
        margin: 1rem 0 2rem;
        width: 100%;
        height: 3.5rem;
        background-color: global.$primary-color;
        border-color: global.$primary-color;
        color: #fff;
    }

    .footer {
        font-weight: 500;
        font-size: 0.9rem;
        margin: 1rem 0;
        color: global.$light-text-color;

        .heading {
            color: global.$primary-color;
            font-weight: 700;
        }
    }

    .error {
        color: global.$twitch-purple;
        font-size: small;
        font-weight: 300;
        line-height: 1.1rem;
        margin: 0.25rem 0 0.25rem;
        color: #DC3545;
    }

    .label {
        color: global.$dark-grey-3;
    }

    .label-note {
        font-size: 0.8rem;
        font-weight: 400;
        color: global.$dark-grey-3;
        margin-top: -0.25rem;
        margin-bottom: 0.25rem;
    }

    .radio-group {
        gap: 0.5rem;
        margin: 0 0 1.4rem;

        label {
            font-size: 1rem;
            padding-top: 2px;
            font-weight: 600;
            color: global.$mid-grey-1;
            text-transform: capitalize;
        }

        .checked {
            color: global.$dark-grey-3
        }
    }

    input[type="radio"] {
        appearance: none;
        background-color: global.$mid-grey-2;
        margin: 0;
        width: 20px;
        height: 20px;
        border: 0 solid #000;
        border-radius: 50%;
        display: grid;
        place-content: center;
    }

    input[type="radio"]:checked {
        background-color: global.$primary-color;
    }

    input[type="radio"]::before {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        transform: scale(0);
        background-color: global.$off-white;
    }

    input[type="radio"]:checked::before {
        transform: scale(1);
    }
}
