@use '../../../scss-fragments/global';

.themes-narrow-panel {
    max-width: 50rem;
    width: fit-content;
    min-width: 40rem;
}

.theme-list {
    display: flex;
    min-height: 10rem;
    width: 100%;

    .theme-list-scroll {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        max-height: 40rem;
        overflow-y: scroll;
        padding-right: 1rem;
    }

    .fl-column {
        flex-grow: 1;
    }

    button {
        margin: 1rem 0 0;
    }

    .list-item {
        border-radius: global.$button-border-radius;
        border-width: 0;
        border-style: solid;
    
        display: flex;
        flex-direction: row;
        gap: 1rem;
        padding: 1rem;
    
        width: 100%;
    
        font-weight: 600;
    
        color: #000;
    }
    
    .li-light {
        background-color: #F3EFFF;
    }
    
    .li-dark {
        background-color: #E4DAFF;
    }
}

.edit-theme-holder {
    width: 98vw !important;
    min-width: 98vw !important;
    max-width: 98vw !important;
    height: 96vh !important;
    min-height: 96vh !important;
    max-height: 96vh !important;
    overflow: hidden !important;

    .main-top-options {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        margin-left: 2rem;
        font-weight: 600;
        color: global.$primary-color;
        cursor: pointer;
    }

    .edit-theme {
        width: 96vw;
        min-width: 96vw;
        max-width: 96vw;
        height: 92vh;
        min-height: 92vh;
        max-height: 92vh;    
    }

    .theme-top {
        width: 90vw;
        margin: auto;
    }

    .tab-content {
        min-height: 72vh;
        max-height: 72vh;
        overflow-y: scroll;
        margin-right: -1rem;
        padding-right: 1rem;
    }

    .content-page-main-content {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

