@use '../../scss-fragments/global';

.settings-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;

    .s-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 2rem;
        row-gap: 0;
    }

    .column-cell {
        display: flex;
        flex-direction: column;
    }

    .no-gap {
        gap: 0;
    }

    .small-gap {
        gap: 1rem;
    }

    .s-profile-pic {
        position: relative;
        flex-shrink: 1;

        .icon-overlay {
            position: absolute;
            right: 0.6rem;
            bottom: 0.6rem;
            width: 2rem;
            height: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .pp-style {
        border-color: global.$mid-grey-2;
    }

    .s-edit-icon {
        color: global.$primary-color;
        cursor: pointer;
    }

    .pp-edit {
        background-color: #FFF;
        border: 0px solid global.$primary-color;
        border-radius: 50em;
    }

    .tw-ppic-icon {
        width: 100%;
    }

    .scroll-area {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 1rem;
        margin: 0;
        padding: 0 1rem 1rem;

        width: 100%;
        max-height: 67vh;
        min-height: 67vh;
        overflow-y: scroll;

        .underlined-panel-heading {
            align-items: center;
            .small-text {
                margin: 0;
            }
            h5 {
                color: global.$dark-grey-3;
            }
            padding-bottom: 0.5rem;
            margin-bottom: 0;
            cursor: pointer;
        }
        .inactive {
            cursor: default;
        }
    }

    .campaign-list-holder {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        height: fit-content;
    }
    .collapsed {
        height: 0px;
        overflow: hidden;
    }
}

.change-password-form {
    width: 50%;

    .submit-btn {
        width: 50% !important;
        margin: 1rem auto !important;
    }
}
