@use "../../../scss-fragments/global";
@use "../../../scss-fragments/forms";

.image-upload-holder {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 0 2rem;
    padding: 0;

    .content {
        width: 100%;
        display: flex;
        gap: 1rem;
    }

    .thumb-left {
        flex-direction: row;
    }
    
    .thumb-top {
        flex-direction: column;
    }

    .thumb-holder {
        position: relative;
        margin: auto;
        flex-shrink: 1;
    }

    .thumb-img {
        position: absolute;
        left: 0;
        top: 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .options-holder {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 1rem;

        .option {
            @include global.unselectable;
            width: 100%;
            flex-grow: 1;
            text-align: center;
        }
    }

    .upload-button {
        @include global.button-style;
        margin: 0;
        width: 100%;
        height: 3.7rem;
        background-color: global.$primary-color;
        border-color: global.$primary-color;
        color: #fff;
    }
}
