/* Fonts */
/* Note the generic naming, so if we decide to change the font our css will still make sense (ie. we won't have Poppins in there, while not actually using Poppins) */
@font-face {
    font-family: PrimaryFont;
    src: url(../assets/fonts/poppins/Poppins-Thin.ttf);
    font-weight: 100;
}
@font-face {
    font-family: PrimaryFont;
    src: url(../assets/fonts/poppins/Poppins-ThinItalic.ttf);
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: PrimaryFont;
    src: url(../assets/fonts/poppins/Poppins-ExtraLight.ttf);
    font-weight: 200;
}
@font-face {
    font-family: PrimaryFont;
    src: url(../assets/fonts/poppins/Poppins-ExtraLightItalic.ttf);
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: PrimaryFont;
    src: url(../assets/fonts/poppins/Poppins-Light.ttf);
    font-weight: 300;
}
@font-face {
    font-family: PrimaryFont;
    src: url(../assets/fonts/poppins/Poppins-LightItalic.ttf);
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: PrimaryFont;
    src: url(../assets/fonts/poppins/Poppins-Regular.ttf);
    font-weight: 400;
}
@font-face {
    font-family: PrimaryFont;
    src: url(../assets/fonts/poppins/Poppins-Italic.ttf);
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: PrimaryFont;
    src: url(../assets/fonts/poppins/Poppins-Medium.ttf);
    font-weight: 500;
}
@font-face {
    font-family: PrimaryFont;
    src: url(../assets/fonts/poppins/Poppins-MediumItalic.ttf);
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: PrimaryFont;
    src: url(../assets/fonts/poppins/Poppins-SemiBold.ttf);
    font-weight: 600;
}
@font-face {
    font-family: PrimaryFont;
    src: url(../assets/fonts/poppins/Poppins-SemiBoldItalic.ttf);
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: PrimaryFont;
    src: url(../assets/fonts/poppins/Poppins-Bold.ttf);
    font-weight: 700;
}
@font-face {
    font-family: PrimaryFont;
    src: url(../assets/fonts/poppins/Poppins-BoldItalic.ttf);
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: PrimaryFont;
    src: url(../assets/fonts/poppins/Poppins-ExtraBold.ttf);
    font-weight: 800;
}
@font-face {
    font-family: PrimaryFont;
    src: url(../assets/fonts/poppins/Poppins-ExtraBoldItalic.ttf);
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: PrimaryFont;
    src: url(../assets/fonts/poppins/Poppins-Black.ttf);
    font-weight: 900;
}
@font-face {
    font-family: PrimaryFont;
    src: url(../assets/fonts/poppins/Poppins-BlackItalic.ttf);
    font-weight: 900;
    font-style: italic;
}
