@use "../../scss-fragments/global";

.restricted-customisation-popup {
    width: 96vw;
    min-width: 96vw;
    height: 95vh;
    min-height: 95vh;
    max-height: 95vh;
    overflow: hidden;

    button.save-button {
        margin: 1.5rem 0 0 auto !important;
        max-width: 20rem !important;
    }
}

.restricted-customisation-popup__scroll-area {
    height: 79vh;
    min-height: 79vh;
    max-height: 79vh;
    padding-bottom: 1rem;
    padding-right: 1rem;
    margin-top: 1.5rem;
    overflow-y: scroll;
}
