@use "../../scss-fragments/global";

html {
	margin:0;
	padding:0;
	overflow: hidden;
}

body{
    margin:0;
	padding:0;
	background-color: rgba(0, 0, 0, 0);
	overflow: hidden;
}

.feature-expired {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0);
    color: #FFF;
    padding: 4rem;

    .content {
        text-align: center;
        img {
            height: 4rem;
            margin-bottom: 1rem;
        }
        h1 {
            color: global.$dark-grey-2;
        }
    }
}

.test-content {
    position: fixed;
    top: 0;
    left: 0;
    margin: 5rem;
    z-index: 1000;

    button {
        @include global.button-style;
        background-color: global.$primary-color;
        color: #FFF;
    }
}

.feature-content {
    iframe {
        position: fixed;
        top: 0;
        left: 0;

        width: 100vw;
        height: 100vh;
        margin: 0;
        padding: 0;
        border: none;
    }

    /* our csl frame has a z-index of 999 so we can be sure it will appear over the top of any loaded features */
    .csl {
        z-index: 9999;
    }
}

.feature-loading {
    position: fixed;
    right: 0rem;
    bottom: 0rem;

    .glitch
    {
        width:4rem;
        height:4rem;
        
    }
}

.preview-bg {
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    width: calc(100vh * 16 / 9);
    max-width: 100vw;
    height: calc(100vw * 9 / 16);
    max-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.masterBox {
    background-color:#66BB6A ;
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    padding:10px;
    z-index: 100000;
    position: fixed;  /* Ensure it's positioned */
    top: 10px;        /* Adjust as needed */
    left: 10px;       /* Adjust as needed */
}


.slaveBox {
    background-color:#E62020 ;
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    padding:10px;
    z-index: 100000;
    position: fixed;  /* Ensure it's positioned */
    top: 10px;        /* Adjust as needed */
    left: 10px;       /* Adjust as needed */
}

