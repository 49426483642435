@use '../../scss-fragments/global';

.circle-perc {
    position: relative;
    width: 5rem;
    height: 5rem;
    margin: 0;
    padding: 0.1rem;

    svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        stroke-linecap: round;
    }

    .back-circle {
        stroke: global.$light-text-color;
        stroke-width: 1.5px;
    }

    .perc-circle {
        stroke: global.$primary-color;
        stroke-width: 2px;
    }
}

.sa-time {
    font-size: 1rem;
    font-weight: 500;
    align-items: center;
    align-content: center;
}

.an-session-list-item {
    border-radius: global.$panel-border-radius;
    border-width: 0;
    border-style: solid;

    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 1rem;
    padding: 1rem;

    width: 100%;

    color: global.$primary-color;

    .stat-text {
        color: global.$dark-grey-3;
    }

    button {
        margin: 0 !important;
        padding: 0.5rem 1rem;
        height: 2.5rem;
    }
}

.an-session-light {
    background-color: #F3EFFF;
}

.an-session-dark {
    background-color: #E4DAFF;
}

.an-stream-summary-holder {
    color: #6E7191;
    font-weight: 500;
}
