.hms-time-field {
    width: 7.5rem;
    max-width: 7.5rem;
    min-width: 7.5rem;
}

.hms-time-field-narrow {
    width: 5rem;
    max-width: 5rem;
    min-width: 5rem;
}

.hms-time-fields-holder {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
    margin: auto;
    gap: 1rem;
    align-items: center;
}
