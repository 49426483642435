@use "../../scss-fragments/global";

.feature-controls-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem 2rem;
    padding-bottom: 2rem;
    margin: 0; /*0 0 1rem;*/

    button {
        border-width: 0;
    }

    .headed-panel {
        margin-bottom: 0 !important;
    }

    .live-control-group-holder {
        min-width: 33rem;
        max-width: 33rem;
    }

    .group-icon {
        svg {
            color: global.$mid-grey-1;
        }
    
        justify-content: center;
        align-content: center;
        display: flex;
        margin-bottom: 0.5rem;
    }

    .control-group {
        /* @include global.panel-style; */
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 0; // 1rem;
        margin: 1rem 0 1rem;
        padding: 1rem;
        border-width: 0;
        border-radius: 1rem;
        background-color: global.$mid-grey-2;
        width: fit-content;
        min-width: 100%;
        max-width: 100%;
        /* min-height: 10rem; */
        max-height: fit-content;

        .inner-box {
            margin: 0;
            padding: 2rem 2rem 1rem;
            background-color: #FFF;
            border-width: 0;
            border-radius: 0.5rem;
        }

        .wide-select {
            width: 100%;
        }

        .var-spacer-line {
            border-top: 1px solid #DDD;
            min-height: 1.5rem;
            max-height: 1.5rem;
            height: 1.5rem;
            margin-top: 0.5rem;
        }
    }

    .ac-userTable{
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 1;
        gap: 1rem;
        width: 100%;
    }
    .lb-userTable{
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 1;
        gap: 1rem;
        width: 100%;
    }
    .leaderboardEntry{
        /* margin-top:10px; */
        /* display: flex;
        flex-direction: row;
        flex-grow: 1;
        gap: 0.5rem;
        width: 100%;
        align-items: center;
        align-content: center;
        justify-content: center;
        justify-items: center; */

        .score-box {
            width: 100%;
        }

        p {
            color: global.$primary-color;
            font-size: 16px;
            font-weight: bold;
            width: 100%;
        }
    }
    .challengeHolder{
        max-height:400px;
        padding:10px;
        width:calc(100% + 20px);
        margin-left:-10px;
        overflow-y: scroll;
        overflow-x: hidden;
        border-radius:20px;
        border:10px solid;
        &.select {
            border-color:  global.$primary-mid-color;
            background-color: global.$primary-mid-color;
        }
        &.ready {
            border-color:  global.$warning-color;
            background-color: global.$warning-color;
        }
        &.live {
            border-color:  global.$success-color;
            background-color: global.$success-color;
        }
    }
    .achievementEntry{
        /* margin-top:10px; */
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 100%;
        padding: 1rem 1rem;
        border-radius: 10px;
        border:2px solid white;
        margin: 1rem 0;
        p {
            width: 100%;
            color: global.$primary-color;
            font-size: 12px;
        }
        .checkbox-outer {
            width: 1rem !important;
        }
        .label {
            margin: 0 !important;
        }
        button {
            margin: 0 !important;
            max-width: 33% !important;
        }
        &.even-row {
            background-color: global.$light-grey-2;
        }
        &.odd-row {
            background-color: global.$light-grey-1;
        }
        &.disabled {
            opacity:.3;
        }
        &.hidden {
           display:none;
        }
        &.unselected {
           
            .select-button {
                filter: grayscale(70%);
                opacity: .8;
            }
        }
        &.selected {
            border:2px solid global.$primary-color;
        }
    }

    .feature-control-holder {
        @include global.standard-rollover-transition;
        display: block;
        overflow: hidden;
        height: 0px;
        opacity: 0;
        margin-bottom: 0;
    }

    .fc-expanded {
        @include global.standard-rollover-transition;
        overflow: visible;
        height: fit-content;
        opacity: 1;
        margin-bottom: 1.5rem;
    }

    .fc-column {
        display: flex;
        flex-direction: column;
        gap: 0;
    }

    .button-grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
        margin: 0 0 1rem;
        padding: 0;
        width: 100%;

        button {
            min-width: 30%;
            width: fit-content;
            display: flex;
            flex: 1;
            margin: 0;
            padding: 0.5rem;
            line-height: 1.2rem;
            position: relative;
        }
    }

    .more-info {
        margin-top: 2rem;
        align-self: center;
        color: global.$primary-color;
    }

    .more-info-2 {
        margin-top: 0.5rem;
        align-self: center;
        color: global.$primary-color;
    }

    .end {
        margin-top: 0;
        align-self: flex-end;
    }

    .start {
        margin-top: 0;
        align-self: flex-start;
    }

    .full-control-highlight {
        padding: 1rem;
        margin: -0.5rem -1rem;
        background-color: global.$success-color !important;
        border: 0px solid global.$success-dark-color !important;
        border-radius: 1rem;
        .label {
            color: #FFF !important;
        }
        .t-on {
            background-color: #FFF !important;
        }
    }
    .full-control-highlight-off {
        padding: 1rem;
        margin: -0.5rem -1rem;
        background-color: global.$warning-color !important;
        border: 0px solid global.$warning-dark-color !important;
        border-radius: 1rem;
        .label {
            color: #FFF !important;
        }
    }

    .var-label {
        font-weight: 700;
        font-size: 1.2rem;
        color: global.$dark-grey-3;
    }

    .info-text {
        margin-bottom: -0.5rem;
        font-weight: 500;
        color: global.$dark-grey-3;
    }
}

.feature-control-header {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-grow: 1;

    color: #FFF;

    h4 {
        color: #FFF !important;
    }
}


.round-info {
    display: flex;
    flex-direction: row;
    width:100%;
    align-items: center;
    font-weight: bold;
    justify-items: center;
    margin: 1rem 0;
    button {
        margin: 0 0 0 auto !important;
    }
}

.live-but-highlighted-overlay {
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    position: absolute;
    top: -3px;
    left: -3px;
    background-color: rgba(255, 255, 255, 0.5);
}

.live-but-spinner {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1.5rem;
    border: solid 0px #000;
    border-radius: 1rem 0 0.5rem 0;
}

.live-but-disabled {
    background-color: global.$mid-grey-1 !important;
    cursor: not-allowed !important;
}

.live-but-disabled-light {
    background-color: global.$mid-grey-2 !important;
    cursor: not-allowed !important;
}

.live-but-purple {
    background-color: global.$primary-color !important;
}

.live-but-purple-light {
    background-color: global.$primary-mid-color !important;
}

.live-qbut-purple {
    color: global.$primary-color !important;
}

.live-qbut-purple-light {
    color: global.$primary-mid-color !important;
}

.live-but-red {
    background-color: global.$error-dark-color !important;
}

.live-but-red-light {
    background-color: global.$error-bg-color !important;
}

.live-qbut-red {
    color: global.$error-dark-color !important;
}

.live-qbut-red-light {
    color: global.$error-bg-color !important;
}

.live-but-green {
    background-color: global.$success-dark-color !important;
}

.live-but-green-light {
    background-color: global.$success-color !important;
}

.live-qbut-green {
    color: global.$success-dark-color !important;
}

.live-qbut-green-light {
    color: global.$success-color !important;
}

.live-but-orange {
    background-color: global.$warning-dark-color !important;
}

.live-but-orange-light {
    background-color: global.$warning-color !important;
}

.live-qbut-orange {
    color: global.$warning-dark-color !important;
}

.live-qbut-orange-light {
    color: global.$warning-color !important;
}

.bgrid-holder {
    width: 100%;
}
