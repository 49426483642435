@use "../../../scss-fragments/global";
@use "../../../scss-fragments/forms";

.drop-down-canceller {
    width: 90vw;
    height: 90vh;
    position: fixed;
    left: 0;
    top: 0rem;
    z-index: 999;
}

.drop-list-holder {
    @include global.unselectable;
    position: relative;

    .drop-down-closed {
        @include global.pill-style;
        margin-bottom: 0;
        margin-top: 0;

        font-weight: 500;
        border-color: global.$mid-grey-1;
        color: global.$mid-grey-1;
        background-color: global.$input-bg-color;
        height: 2.5rem;

        cursor: pointer;
    }

    .top-row {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;
        justify-items: center;
    }

    .drop-down-list {
        @include global.pill-style;
        border-radius: 1.25rem;
        margin-bottom: 0;
        margin-top: 0;

        position: absolute;
        left: 0;
        top: 0;
        z-index: 10001;
        min-width: 100%;
        white-space: nowrap;

        font-weight: 500;
        border-color: global.$mid-grey-1;
        color: global.$mid-grey-1;
        background-color: global.$input-bg-color;
        height: fit-content;

        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        padding: 0.4rem 1rem;
    }

    .item {
        width: 100%;
        cursor: pointer;
    }

    .label-holder {
        flex-grow: 1;
    }

    .default-selected {
        border-color: global.$primary-color;
        color: global.$primary-color;
        background-color: global.$input-bg-color;
    }

    .drop-down-disabled {
        border-color: global.$mid-grey-1;
        color: global.$mid-grey-1;
    }

    .option {
        @include global.standard-rollover-transition;
        color: global.$dark-grey-3;
    }

    .option:hover {
        color: global.$primary-color;
    }
}
