@use "../../scss-fragments/global";
.edit-quiz-container {
  overflow: auto;
  width:100%;
  margin:15px;
  padding:15px

  input {
    max-width: 500px;
    width:500px;
    min-width: 165px;
  }

  .drop-list-holder {
    max-width: 500px;
  }

  .form-holder .submit-btn {
    max-width: 800px;
    margin: 30px auto;
  }
}

.quiz-type-holder {
  width: 20rem;
}

.quiz-round-panel {
  width: 25rem;
  height: 24rem;

  .quiz-questions-holder {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-self: flex-start;

    button {
      margin: 0;
    }

    .small-add-button {
      margin-right: 0 !important;
      margin-top: -3px !important;
    }

    .questions-list {
      height: 20rem;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-bottom: 0;
      padding-right: 0.5rem;
    }
  }
}

.quiz-heading {
  button {
    margin: 0;
  }
}

/* .co-fullcol-scroll-area {
  max-height:none;
  height: 100%;
} */

.scroll {
  overflow-y:auto;
  max-height:100vh;
  min-height:250px;
}

.quiz-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.quiz-panel {
  @include global.panel-style;
  @include global.panel-shadow;
  margin: 0 0 1rem;

  position: relative;

  width: 100%;
  max-height: calc(100% - 6rem);
  flex-grow: 1;

  color: global.$label-color;
  font-weight: 500;
}
.question_holder{

}
.close-add{
  margin-top:-5px;
}
.answer-box{
  border-left:1px solid global.$mid-grey-1;
  padding-left: 20px;
  height:100%;
  width:100%;
  min-width:470px;
  max-width:470px;
}
.answer-title{
  /* padding-top: 10px; */
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid global.$light-grey-1;
  .small-add-button {
    width: 2rem;
    height: 2rem;
  }

 
}

.scroll-edit-answers {
  overflow-y: scroll;
  height: calc(100vh - 28.5rem);
  min-height: calc(100vh - 28.5rem);
  max-height: calc(100vh - 28.5rem);
  width:100%;
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
}

.flex{
  display:flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  align-items: center;
  align-items: flex-start;
  gap:20px;
}
.small-gap{
  gap:10px;
}
.standard-button:disabled {
  opacity:.7;
}
.answer_holder {
  margin-top: 20px;
  width:100%;
  min-width:20px;
  max-width:470px;
  border: 1px solid global.$light-grey-1;;

  
  border-radius: 20px;
  padding:10px;
 
}
.fulltextArea {
  width:49%
}
.single-answer{
  border-radius: 10px;
  padding-bottom: 10px;
  /* border-bottom: 1px solid global.$light-grey-1;; */
}
.single-answer p{
  padding-left: 12px;;
  padding-top:5px;
}
.hashtag-pill{
  min-width:310px;
  text-align: left;
  padding-top:7px;
  padding-left:12px;
  border-radius: 50em;
  background-color: global.$light-grey-1;
  height:30px;
}
.correct{
  background-color: global.$success-dark-color;
  color: white;
}
.incorrect{
  background-color: global.$error-dark-color;
  color: white;
}
.correct-answer-box, .incorrect-answer-box{
  width:30px;
  height:30px;
  border-radius: 50em;
  background-color: global.$success-dark-color;
  color: white !important;
  text-align: center;
  padding-top:7px;
  min-width: 2.4rem;
}
.incorrect-answer-box{
  background-color: global.$error-dark-color;
}
.img-preview img{
  max-width: 75px;
}
.answers{
  padding-top:10px;
}

.round-box-overview{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  padding-right: 1rem;
  overflow-y: scroll;
  height: calc(100vh - 28.5rem);
  width:100%;
}