@use "../../scss-fragments/global";

.chain-timeline-holder {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;

    .start-button {
        display: flex;
        min-width: 5rem;
        width: 5rem;
        height: 5rem;
        background-color: global.$primary-color;
        color: global.$off-white;
        border: solid 2px global.$primary-color;
        border-radius: 100em;
        text-align: center;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .cell {
        width: 25%;
        min-width: fit-content;
        max-width: 28rem;
        height: 5rem;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        padding: 0 1rem;

        align-items: center;

        background-color: #FFF;
        color: global.$body-copy-color;
        border: solid 2px global.$primary-color;
        border-radius: 100em;

        .content {
            display: flex;
            flex-direction: column;
            flex-grow: 100;
        }
    }

    .disabled {
        border-color: global.$mid-grey-2;
        background-color: global.$light-grey-1;
        color: global.$mid-grey-1;
        cursor: default;
    }

    .active {
        border-color: global.$success-dark-color;
        background-color: global.$success-color;
    }

    .divider {
        min-width: 0.5rem;

        display: flex;
        flex-grow: 1;
        height: 2rem;

        background-image: url(../../assets/horiz-timeline-divider.svg);
        background-repeat: repeat-x;
        background-position: center;
        background-size: 50px 2px;
    }
}

.feature-chain-position-holder {
    display: flex;
    min-width: 4rem;
    width: 4rem;
    height: 4rem;
    background-color: global.$panel-body-bg;
    color: global.$mid-grey-2;
    border: solid 2px global.$mid-grey-2;
    border-radius: 100em;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: 500;
}

.active-chain-position {
    color: #FFF;
    border-color: global.$success-dark-color;
    background-color: global.$success-dark-color;
}

.enabled-chain-position {
    color: global.$primary-color;
    border-color: global.$primary-color;
    background-color: global.$primary-light-color;
}
