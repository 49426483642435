.main-site-container {
    display: flex;
    flex-direction: column;
    /* width: 100vw; */
    height: 100vh;
    margin: 0;
    padding: 0;
}

.under-top-bar {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    height: calc(100vh - 4rem);
    margin: 4rem 0 0;
    padding: 0;
}
.no-top-bar {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    height: 100vh;
    margin: 0;
    padding: 0;
}
.glitch
{
    width:7rem;
    height:7rem;
    filter:none;
}
.initialising-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height:100vh;
}
.nav-pane {
    display: flex;
    flex-shrink: 1;
}

.content-pane {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
}
