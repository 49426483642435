@use '../../scss-fragments/global';

.stream-control-bar {
    position: sticky;
    bottom: 0;
    right: 0;

    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;

    width: 100%;
    min-height: 5rem;
    max-height: 5rem;
    padding: 0 2rem;

    font-weight: 600;
    white-space: nowrap;
    
    button {
        max-width: fit-content;
        margin: 0;
    }

    h4 {
        margin: 0;
    }
}

.control-connected {
    background-color: global.$success-color;
}

.control-disconnected {
    background-color: global.$light-grey-1;
}
