@use "../../scss-fragments/global";
@use "../../scss-fragments/forms";

.confirm-login-pop-canceller {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0rem;
    z-index: 999;
}
.confirm-login-pop-canceller {
    background-color: global.$dark-grey-1;
    opacity: 80%;
}

.confirm-login-pop-container {
    @include global.button-style;
    // @include global.panel-shadow;
    @include global.standard-rollover-transition;

    display: block;
    position: fixed;
    left: calc((50% - (30rem/2)));
    top: calc((50% - (40rem/2)));
    z-index: 1000;

    text-align: left;

    width: 26rem;
    height: 36rem;
    margin: 0.5rem;
    padding: 0.5rem;

    background-color: global.$off-white;
    border-color: global.$dark-grey-2;
    color: global.$mid-grey-1;
    opacity: 100%;

    transform: scale(1);
    transform-origin: 100% 0;
}

.confirm-login-pop-container-hidden, .confirm-login-pop-canceller-hidden {
    display: none;
}

.confirm-login-header {
    text-align: center;
    position: relative;
    padding-bottom: 15px;
    margin: auto;
    width: 350px;
}

.confirm-login-logo-holder {
    position: relative;
    margin: auto;
    width: 118px;
}

p.confirm-login-text {
    text-align: center;
    font-size: 12; //this won't work, gets overidden by body, even if I use !important
}

// .confirmation-code-input-holder {
//     display: flex;
// }
.submit-btn-holder {
    display: flex;
    flex-grow: 1;
}
.confirmation-code-input-holder .submit-btn {
    align-self: flex-end;
}

.confirm-login-close-holder {
    width: 100%;
    position:relative;
    .confirm-login-close {
        float: right;
        width: 38px;
        height: 38px;
        border-style: solid;
        border-color: global.$primary-color;
        border-width: 2px;
        border-radius: 100px;
        text-align: center;
        line-height:2.1rem;
        color: global.$primary-color;
        font-size:large;
        font-weight:400;
    }
}
