@use "../../../scss-fragments/global";
@use "../../../scss-fragments/forms";

.s-min-max-display {
    margin-top: 0.25rem;
}

.s-min-display {
    width: 25%;
    text-align: left;
}

.s-mid-display {
    width: 50%;
    text-align: center;
}

.s-max-display {
    width: 25%;
    text-align: right;
}

/* The slider itself */
.slider {
    -webkit-appearance: none;
    /* Override default CSS styles */
    appearance: none;
    width: 100%;
    /* Full-width */
    height: fir-content;
    /* Specified height */
    background: #d3d3d3;
    /* Grey background */
    /* outline: none; */
    /* Remove outline */
    /* opacity: 0.7; */
    /* Set transparency (for mouse-over effects on hover) */
    /* -webkit-transition: .2s; */
    /* 0.2 seconds transition on hover */
    /* transition: opacity .2s; */

    border-radius: global.$button-border-radius;
    border-width: 0 !important;
    border-style: solid;
    border-color: global.$mid-grey-2;
    background-color: global.$mid-grey-2;
    margin: 0;
    height: 8px !important;
    padding: 0 !important;
}

.slider:focus {
    background-color: global.$mid-grey-2 !important;
}

/* Mouse-over effects */
.slider:hover {
    opacity: 1;
    /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Override default look */
    appearance: none;
    width: 1.5rem;
    /* Set a specific slider handle width */
    height: 1.5rem;
    border: solid 0px;
    border-radius: 50em;
    /* Slider handle height */
    background: global.$primary-color;
    /* Green background */
    cursor: pointer;
    /* Cursor on hover */
}

.slider::-moz-range-thumb {
    width: 25px;
    /* Set a specific slider handle width */
    height: 25px;
    /* Slider handle height */
    background: global.$primary-color;
    /* Green background */
    cursor: pointer;
    /* Cursor on hover */
}