@use "../../../scss-fragments/global";
@use "../../../scss-fragments/forms";

.form-field-holder {
    @include forms.form-field-holder;

    .form-field-content-holder {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
    }
    
    .form-field-content {
        position: relative;
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        gap: 1rem;
        align-items: center
    }

    .force-small-gap {
        margin-bottom: 0.5rem;
    }
    
    input {
        @include forms.form-field-style;
        width: 100%;
    }
    
    input:focus {
        outline-color: global.$primary-color;
        background-color: global.$off-white;
    }

    /* input[readonly] {
        padding: 0;
        height: 1.5rem;
        margin-bottom: 0.5rem;
        background-color: global.$off-white;
        border-color: global.$off-white;
    }

    input[readonly]:focus {
        background-color: global.$off-white;
        outline-width: 0px
    } */

    input[readonly]:focus {
        background-color: global.$mid-grey-2;
    }
}
