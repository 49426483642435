@use "../../scss-fragments/global";

.stream-time {
    text-transform: lowercase;
    font-weight: 400;
    color: global.$mid-grey-2;
}

.stream-time-dark {
    text-transform: lowercase;
    font-weight: 400;
    color: global.$mid-grey-1;
}

.stream-twitch-logo {
    svg {
        height: 2rem;
    }
    path {
        fill: currentColor;
        stroke: currentColor;
    }
}

.stream-details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-weight: 500;
    color: global.$label-color;

    .time {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
