@use "../../scss-fragments/global";

.tabbed-panel {
    @include global.panel-shadow;
}

.tabbed-panel-content {
    @include global.panel-style;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin: 0;
}

.top-right-curve {
    border-top-right-radius: global.$panel-border-radius;
}

.tabbed-panel-tab {
    @include global.tab-style;
    cursor: default;
}

.tabbed-panel-tab-inactive {
    background-color: global.$primary-color;
    border-color: global.$primary-color;
    color: global.$light-grey-1;
    cursor: pointer;
}

.tabbed-panel-tabs-row {
    width: 100%;
    display: flex;
    flex-direction: row;
}
