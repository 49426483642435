@use '../../scss-fragments/global';

.connecting-popup-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    text-align: center;
    margin-top: 1rem;

    h4 {
        color: global.$label-color;
    }

    .icon {
        @include global.round-button-style;
        cursor: default;
        margin: 0 auto 2rem;
    }

    .inactive {
        border-color: global.$light-text-color;
        color: global.$light-text-color;
    }

    .busy {
        border-color: global.$primary-color;
        color: global.$primary-color;
    }

    .green {
        border-color: global.$success-dark-color;
        color: global.$success-dark-color;
    }

    .red {
        border-color: global.$error-dark-color;
        color: global.$error-dark-color;
    }

    @keyframes busy-rotate-frames
    {
        from { transform: rotate(0deg) }
        to { transform: rotate(360deg) }  /**change to a higher value for more seamless loop*/
    }

    .busy-anim { 
        transform-origin: center;
        animation: busy-rotate-frames 1s ease-in-out infinite;  /**change the value to make rotation faster or slower*/
    }
}
