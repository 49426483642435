@use "../../scss-fragments/global";
@use "../../scss-fragments/forms";

.seeder-editor-panel {
    @include global.panel-style;
    @include global.panel-shadow;
    margin: 0 3rem 2rem;

    position: relative;

    width: calc(100% - 6rem);
    /* max-height: calc(100% - 6rem); */
    /* flex-grow: 1; */

    color: global.$label-color;
    font-weight: 500;
}

.fe-three-quarter-width {
    min-width: 74%;
    max-width: 74%;
}

.fe-quarter-width {
    min-width: 24%;
    max-width: 24%;
}

.cosmetic-var {
    background-color: global.$secondary-bg-color !important;
}

.seeder-content-box {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0.5rem;
    border-radius: global.$panel-border-radius;
    border-width: 1px;
    border-style: solid;
    border-color: global.$mid-grey-2;
    background-color: global.$light-grey-1;
    margin: 0;
    width: fit-content;
    min-width: 19%;
    max-width: fit-content;

    &.outline {
        background-color: global.$panel-body-bg;
    }

    &.no-margin {
        margin: 0;
    }

    .del-icon {
        position: absolute;
        top: -1rem;
        right: -1rem;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 2rem;
        height: 2rem;

        border: solid 0px #000;
        border-radius: 50%;
        background-color: global.$primary-color;
        color: #FFF;

        cursor: pointer;
    }

    .insert-above-icons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        flex-direction: row;

        position: absolute;
        top: -1rem;
        left: -1rem;

        .insert-above-icon {
            display: flex;
            align-items: center;
            justify-content: center;
    
            width: 2rem;
            height: 2rem;
    
            border: solid 0px #000;
            border-radius: 50%;
            background-color: global.$primary-color;
            color: #FFF;
    
            cursor: pointer;
        }
    }

    .move-icons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        flex-direction: row;

        position: absolute;
        top: -1rem;
        right: 1.5rem;

        .move-icon {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 2rem;
            height: 2rem;

            border: solid 0px #000;
            border-radius: 50%;
            background-color: global.$primary-color;
            color: #FFF;

            cursor: pointer;
        }
    }

    &.test-command {
        background-color: global.$secondary-bg-color;
    }

    &.quarter-width {
        min-width: 24%;
        max-width: 24%;
    }

    .quarter-width {
        min-width: 24%;
        max-width: 24%;
    }

    .third-width {
        min-width: 32%;
        max-width: 32%;
    }

    &.half-width {
        min-width: 49%;
        max-width: 49%;
    }

    .half-width {
        min-width: 49%;
        max-width: 49%;
    }

    &.three-quarter-width {
        min-width: 74%;
        max-width: 74%;
    }

    .three-quarter-width {
        min-width: 74%;
        max-width: 74%;
    }

    &.full-width {
        min-width: 100%;
        max-width: 100%;
    }

    &.width-99 {
        min-width: 99%;
        max-width: 99%;
    }

    .eval-width {
        min-width: 5rem;
        max-width: 5rem;
    }

    .eval-var-width {
        min-width: 50%;
        max-width: 50%;
    }

    .eval-var-width-live {
        min-width: 40%;
        max-width: 50%;
    }

    .force-width-options {
        min-width: 12rem;
        max-width: 12rem;
    }

    .icon-preview {
        svg {
            color: global.$primary-color;
        }
        justify-content: center;
        margin-top: 1.5rem;
    }
}

.live-edit-var-family-pop-up {
    width: 900px;
    max-width: 900px !important;
}

.live-edit-var-family-popup-content {
    width: 850px;
    height: 90vh;
    padding: 1rem;
    margin: 3rem 0 1rem 0;
}
