@use "../../scss-fragments/global";

.top-bar {
    position: fixed;
    left: 0;
    top: 0;

    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 4rem;
    margin: 0;
    padding: 0;
    background-color: global.$top-bar-color;
    font-weight: 500;

    z-index: 100;

    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);

    .logo {
        width: 2.1rem;
        margin: 0.8rem 1.2rem 0.8rem 0.8rem;
    }

    .icast-logo {
        height: 2.4rem;
        margin: 0.8rem 0.8rem 0.8rem 1.2rem;
    }

    .left {
        flex-grow: 1;
        font-size: 1.3rem;
    }

    .right {
        flex-shrink: 1;
    }

    .pp-style {
        margin: 0.6rem;
        width:3rem;
        height:3rem;
        border-color: global.$top-bar-color;
        cursor: pointer;
    }
}
