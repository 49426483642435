@use "../../scss-fragments/global";

.nav-bar-container {
    @include global.panel-shadow;
    
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #fff;

    transition: width 0.3s;
    overflow: hidden;

    & a {
        text-decoration: none;

        &:hover {
            text-decoration: none;
            color: global.$primary-color !important;
        }
        &:visited {
            text-decoration: none;
        }
        &:active {
            text-decoration: none;
        }
    }
}

.nav-bar-icon {
    width: 1.8rem;
    margin: 0;
}

.nav-bar-text {
    padding-right: 0.25rem;
    padding-left: 0.5rem;

    transition: opacity 0.3s
}

.nav-collapsed {
    width: 3.25rem;

    .nav-bar-text {
        opacity: 0;
    }
}

.nav-expanded {
    width: 11rem;

    .nav-bar-text {
        opacity: 1;
    }
}

.nav-expander-container {
    color: global.$primary-color;
    border-color: #fff;
}

.nav-active {
    color: global.$primary-color;
    border-color: global.$primary-color;

    & a {
        color: global.$primary-color;

        &:visited {
            color: global.$primary-color;
        }
        &:active {
            color: global.$primary-color;
        }
    }
}

.nav-inactive {
    border-color: #fff;
    color: global.$dark-grey-3;

    & a {
        color: global.$dark-grey-3;

        &:visited {
            color: global.$dark-grey-3;
        }
        &:active {
            color: global.$dark-grey-3;
        }
    }
}

.nav-bar-section {
    @include global.unselectable;

    min-width: 3rem;
    width: 100%;
    height: 2rem;
    margin: 0.5rem 0.5rem 0.5rem 0;
    padding-left: 0.5rem;
    border-left-width: 4px;
    border-left-style: solid;
    
    display: flex;
    align-items: center;
    font-weight: 500;

    white-space: nowrap;
}

.nav-bar-top-section-spacer {
    height: 5rem;
}

.nav-bar-bottom-section-spacer {
    flex-grow: 1;
}
