@use "../../scss-fragments/global";

.schedule-column {
    display: flex;
    flex-direction: column;
    gap: 0;
    align-self: flex-start;
}

.sch-r {
    .content-page-main-content {
        padding-left: 0;
        padding-bottom: 1rem;
    }
    .content-page-top {
        margin-left: 0;
        margin-right: 0;
        width: calc(100% - 3rem);
        min-height: 10.8rem;
    }
}

.schedule-holder {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    width: 100%;
    /* height: 75vh; */

    .count-pill {
        @include global.pill-style;
        background-color: global.$primary-color;
        border-color: global.$primary-color;
        color: #FFF;
        max-width: fit-content;
        padding: 0 0.75rem;
        gap: 0.5rem;
        font-weight: 600;
    }

    .count-today {
        background-color: #FFF;
        border-color: #FFF;
        color: global.$primary-color,
    }

    .count-zero {
        opacity: 0;
    }

    .count-ended {
        background-color: global.$mid-grey-2;
        border-color: global.$mid-grey-1;
        color: global.$mid-grey-1,
    }

    .upcoming-streams {
        min-width: 27rem;
        min-height: calc(100vh - 18rem);
        max-height: calc(100vh - 18rem);
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .react-calendar {
        width: 100%;
        height: 100%;
        padding: 0;

        border-width: 0;
        background-color: rgba($color: #000000, $alpha: 0)/* global.$body-bg-color */;
        filter: none;
    }

    .react-calendar__tile {
        padding: 1.5rem;
        text-align: left;
        border: 1px solid #94A3B8;
        height: 100%;
        background-color: #FFF;
        color: #475569;
        font-weight: 400;

        p {
            margin-top: 2rem;
        }
    }

    .react-calendar__navigation button:disabled {
        background-color: rgba($color: #000000, $alpha: 0)/* global.$body-bg-color */;
        color: #475569;
    }

    .react-calendar__tile:hover,
    .react-calendar__tile:focus {
        background-color: global.$primary-light-color;
    }

    .react-calendar__tile--now {
        background-color: global.$primary-color;
        color: #FFF;
    }

    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus {
        background-color: global.$primary-light-color;
        color: global.$primary-color;
    }

    .react-calendar__tile--active {
        background-color: global.$primary-light-color;
        color: global.$primary-color;
    }

    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
        background-color: global.$primary-light-color;
        color: global.$primary-color;
    }

    .react-calendar__tile button:enabled:hover,
    .react-calendar__tile button:enabled:focus {
        background-color: global.$primary-light-color;
        color: global.$primary-color;
    }

    

      
      .react-calendar__tile--hasActive {
        background-color: global.$primary-light-color;
        color: global.$primary-color;
      }
      
      .react-calendar__tile--hasActive:enabled:hover,
      .react-calendar__tile--hasActive:enabled:focus {
        background-color: global.$primary-light-color;
        color: global.$primary-color;
      }
      
      
      .react-calendar__tile--active:enabled:hover,
      .react-calendar__tile--active:enabled:focus {
        background-color: global.$primary-light-color;
        color: global.$primary-color;
      }
      
      .react-calendar--selectRange .react-calendar__tile--hover {
        background-color: global.$primary-light-color;
        color: global.$primary-color;
      }

      .react-calendar__month-view__weekdays__weekday {
        font-size: 1rem;
        border: 1px solid #94A3B8;
        margin-bottom: 0.5rem;
        text-transform: capitalize;
        background-color: #FFF;
        color: #475569;
        font-weight: 500;
      }

      .react-calendar__month-view__days__day--neighboringMonth {
        background-color: #F8FAFC;
        color: #94A3B8;

      }

      .react-calendar__navigation {
        font-size: 1.6rem;
        font-weight: 400;
        height: 30px;
        margin-bottom: 1rem;
        gap: 0.5rem;
    }

    .react-calendar__navigation__prev-button,
    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next-button,
    .react-calendar__navigation__next2-button,
    .react-calendar__navigation__prev-button:hover,
    .react-calendar__navigation__prev2-button:hover,
    .react-calendar__navigation__next-button:hover,
    .react-calendar__navigation__next2-button:hover,
    .react-calendar__navigation__prev-button:active,
    .react-calendar__navigation__prev2-button:active,
    .react-calendar__navigation__next-button:active,
    .react-calendar__navigation__next2-button:active {
        /* border: 2px solid global.$primary-color;
        color: global.$primary-color;
        border-radius: 0.3rem; */
        @include global.button-style;
        margin: 0.5rem 0;
        background-color: global.$primary-color !important;
        border-color: global.$primary-color !important;
        color: #fff !important;
    }

}

.content-page-top {
    display: block!important;
}

