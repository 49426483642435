@use '../../scss-fragments/global';

.headed-panel {
    @include global.panel-shadow;

    .heading-pane {
        @include global.panel-style;
       
        position: relative;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin: 0;
        min-height: 4rem;

        /* text-transform: uppercase; */
        letter-spacing: 1px;
        font-size: 1.3rem;

        display: flex;
        flex-direction: row;
        gap: 0.5rem;
    }

    .content-pane {
        @include global.panel-style;
        @include global.standard-rollover-transition;
       
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin: 0;
        min-height: calc(100% - 5.2rem - 4px);

        display: flex;
        flex-direction: column;
        gap: 0.1rem;

        p {
            margin: 0;
        }

        h3 {
            margin: 0;
        }

        h4 {
            margin: 0 0 0.4rem;
        }
    }

    .content-holder {
        @include global.standard-rollover-transition;
        margin: 0;
        padding: 0;
        min-height: fit-content;
        height: fit-content;
        max-height: fit-content;
    }

    .content-collapsed {
        min-height: 0px;
        height: 0px;
        max-height: 0px;
        padding-top: 0px;
        overflow: hidden;
    }

    .default-color-heading {
        border-color: #000;
        background-color: #000;
        color: #FFF;
    }

    .default-color-content {
        border-color: #000;
    }

    .live-color-heading {
        border-color: global.$success-color;
        background-color: global.$success-color;
        color: global.$success-dark-color !important;

        .icon {
            color: global.$off-white;
        }
    }

    .live-color-content {
        border-color: global.$success-color;
    }

    .scheduled-color-heading {
        border-color: global.$warning-color;
        background-color: global.$warning-color;
        color: global.$warning-dark-color !important;
    }

    .scheduled-color-content {
        border-color: global.$warning-color;
    }

    .ended-color-heading {
        border-color: global.$mid-grey-1;
        background-color: global.$mid-grey-1;
        color: global.$dark-grey-2 !important;
    }

    .ended-color-content {
        border-color: global.$mid-grey-1;
    }

    .primary-color-heading {
        border-color: global.$primary-color;
        background-color: global.$primary-color;
        color: global.$off-white !important;
    }

    .primary-color-content {
        border-color: global.$primary-color;
        color: global.$light-text-color;
    }

    .primary-light-color-heading {
        border-color: global.$primary-mid-color;
        background-color: global.$primary-mid-color;
        color: global.$off-white !important;
    }

    .primary-light-color-content {
        border-color: global.$primary-mid-color;
        color: global.$light-text-color;
    }

    .demo-color-heading {
        border-color: global.$primary-color;
        background-color: global.$primary-color;
        color: global.$off-white !important;
    }

    .demo-color-content {
        border-color: global.$primary-color;
        color: global.$light-text-color;
    }

    .archived-color-heading {
        border-color: global.$mid-grey-2;
        background-color: global.$mid-grey-2;
        color: global.$mid-grey-1 !important;
    }

    .archived-color-content {
        border-color: global.$mid-grey-2;
        color: global.$mid-grey-1;
    }

    .error-color-heading {
        border-color: global.$error-dark-color;
        background-color: global.$error-dark-color;
        color: global.$error-light-color;
    }

    .error-color-content {
        border-color: global.$error-dark-color;
        color: global.$error-dark-color;
    }

    .an-stream-summary-color-heading {
        border-color: global.$mid-grey-2;
        background-color: global.$mid-grey-2;
        color: global.$dark-grey-3 !important;
    }

    .an-stream-summary-color-content {
        border-color: global.$mid-grey-2;
        color: global.$dark-grey-3;
    }

    .an-streams-color-heading {
        border-color: #EC6624;
        background-color: #EC6624;
        color: #FFF;
    }

    .an-streams-color-content {
        border-color: #EC6624;
        color: global.$light-text-color;
    }

    .an-interactions-color-heading {
        border-color: #FFD789;
        background-color: #FFD789;
        color: #FFF !important;
    }

    .an-interactions-color-content {
        border-color: #FFD789;
        color: global.$light-text-color;
    }

    .an-u-users-color-heading {
        border-color: #5DE8A0;
        background-color: #5DE8A0;
        color: #FFF !important;
    }

    .an-u-users-color-content {
        border-color: #5DE8A0;
        color: global.$light-text-color;
    }

    .an-u-i-users-color-heading {
        border-color: #7193FD;
        background-color: #7193FD;
        color: #FFF;
    }

    .an-u-i-users-color-content {
        border-color: #7193FD;
        color: global.$light-text-color;
    }

    .an-brand-engagement-color-heading {
        border-color: #F87CA8;
        background-color: #F87CA8;
        color: #FFF !important;
    }

    .an-brand-engagement-color-content {
        border-color: #F87CA8;
        color: global.$light-text-color;
    }
}

.small-headed-panels {
    .heading-pane {
        font-size: 1.1rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: 0.4rem 0.5rem;
        min-height: 3rem;
        max-height: 3rem;
    }

    .content-pane {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        padding: 0.5rem;
        min-height: auto;
    }
}

.no-panel-shadow {
    .headed-panel {
        filter: none;
    }
}
