@use '../../../scss-fragments/global';

.stacking-panel {
    max-width: 50rem;
    width: fit-content;
    min-width: 40rem;
}

.edit-order-button {
    width: 2rem;
    height: 2rem;
    max-width: 2rem;
    max-height: 2rem;
    min-width: 2rem;
    min-height: 2rem;
    background-color: #FFF;
    border: 2px solid global.$primary-color;
    border-radius: 50%;
    color: global.$primary-color;

    display: flex;
    align-items: center;
    justify-content: center;
    
    cursor: pointer;
}

.edit-but-off {
    cursor: default;
    background-color: global.$mid-grey-2;
    color: global.$mid-grey-1;
    border-color: global.$mid-grey-2;
}

.stack {
    display: flex;
    min-height: 15rem;
    width: 100%;

    .stack-scroll {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        max-height: 40rem;
        overflow-y: scroll;
        padding-right: 1rem;
    }

    .fl-column {
        flex-grow: 1;
    }

    button {
        margin: 1rem 0 0;
    }

    .list-item {
        border-radius: global.$button-border-radius;
        border-width: 0;
        border-style: solid;
    
        display: flex;
        flex-direction: row;
        gap: 1rem;
        padding: 1rem;
    
        width: 100%;
    
        font-weight: 600;
    
        color: #000;
    }
    
    .li-light {
        background-color: #F3EFFF;
    }
    
    .li-dark {
        background-color: #E4DAFF;
    }
}
