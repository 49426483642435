@use "../../scss-fragments/global";

.customisation-preview-holder {
    width: 98vw !important;
    min-width: 98vw !important;
    max-width: 98vw !important;
    height: 96vh !important;
    min-height: 96vh !important;
    max-height: 96vh !important;
    overflow: hidden !important;

    .customisation-preview {
        width: 96vw;
        min-width: 96vw;
        max-width: 96vw;
        height: 92vh;
        min-height: 92vh;
        max-height: 92vh;    
    }

    .preview-top {
        width: 90vw;
        margin: auto;
    }

    .tab-content {
        min-height: 72vh;
        max-height: 72vh;
        overflow-y: scroll;
        margin-right: -1rem;
        padding-right: 1rem;
    }

    .content-page-main-content {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .edit-layout {
        position: relative;

        .icon {
            color: global.$primary-color;
            cursor: pointer;
        }

        .layout-settings-holder {
            position: absolute;
            top: 0rem;
            right: 2rem;
            width: 18rem;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            background-color: global.$light-grey-2;
            box-shadow: 0 0 5px 1px global.$dark-grey-3;
            padding: 1rem;
            border: 0px solid global.$dark-grey-3;
            border-radius: global.$panel-border-radius;
            z-index: 1000;
        }

        .move-icons {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            flex-direction: row;
            padding: 0.5rem;
    
            position: absolute;
            top: -2.5rem;
            right: -0.5rem;

            z-index: 2;
    
            cursor: default;

            .move-icon {
                display: flex;
                align-items: center;
                justify-content: center;
    
                width: 1.2rem;
                height: 1.2rem;
    
                border: solid 0px #000;
                border-radius: 50%;
                background-color: global.$primary-color;
                color: #FFF;
    
                cursor: pointer;
            }
        }

        .group-move-icons {
            top: -0.4rem;
            right: 2rem;
        }
    }
}
