@use "../../../scss-fragments/global";
@use "../../../scss-fragments/forms";


.toggle-content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    .disabled {
        opacity: 0.5;
    }
}

.toggle-holder {
    @include forms.form-field-holder;

    .toggle-outer {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 4rem;
    }


    .toggle-track {
        @include global.standard-rollover-transition;

        position: relative;

        width: 4rem;
        height: 2.2rem;
        min-width: 4rem;
        min-height: 2.2rem;
        border-radius: 50em;
        border-width: 0;
        border-style: solid;
        border-color: global.$dark-grey-1;
    }

    .t-on {
        background-color: global.$success-bg-color;
    }

    .t-off {
        background-color: global.$error-bg-color;
    }

    .toggle-switch {
        @include global.standard-rollover-transition;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 2.2rem;
        height: 2.2rem;
        min-width: 2.2rem;
        min-height: 2.2rem;
        border-radius: 50em;
        border-width: 2px;
        border-style: solid;
        color: global.$off-white;
    }

    .s-on {
        right: 0;
        border-color: global.$success-dark-color;
        background-color: global.$success-dark-color;
    }

    .s-off {
        right: calc(100% - 2rem);
        border-color: global.$error-dark-color;
        background-color: global.$error-color;
    }
}
