@use "../../../scss-fragments/global";
@use "../../../scss-fragments/forms";

.text-area-holder {
    @include forms.form-field-holder;

    .text-area-content-holder {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
    }
    
    .text-area-content {
        display: flex;
        flex-grow: 1;
    }
    
    textarea {
        @include forms.text-area-style;
        resize: none;
        width: 100%;
    }
    
    textarea:focus {
        outline-color: global.$primary-color;
        background-color: global.$off-white;
    }
}

