@use "../../scss-fragments/global";


.campaign-panel {
    width: 25rem;
    min-height: 25rem;
    height: fit-content;

    .detail-label {
        font-weight: 500;
        color: global.$light-text-color;
    }

    .detail-info-1 {
        font-weight: 600;
        color: global.$primary-color;
    }

    .detail-info-2 {
        font-weight: 600;
        color: global.$dark-grey-3;
    }

    .info-pill {
        @include global.pill-style;
        margin: 0;
        padding: 0 0.4rem;
        font-size: 0.8rem;
        font-weight: 600;
        min-height: 1.6rem;
        max-height: 1.6rem;
        text-transform: uppercase;

        display: flex;
        flex-direction: row;
    }

    .active {
        border-color: global.$success-dark-color;
        background-color: global.$success-bg-color;
        color: global.$success-dark-color;
    }
    
    .scheduled {
        border-color: global.$warning-color;
        background-color: global.$warning-light-color;
        color: global.$warning-color;
    }
    
    .archived {
        border-color: global.$mid-grey-1;
        background-color: global.$mid-grey-2;
        color: global.$mid-grey-1;
    }

    .stream-data {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;

        color: global.$label-color;
        font-weight: 500;
        font-size: 1.1rem;
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;

        svg {
            height: 1.2rem;
        }
        path {
            fill: currentColor;
            stroke: currentColor;
        }
    }

    .watch-btn {
        @include global.button-style;
        margin: 0;
        height: 4rem;
        background-color: global.$primary-color;
        border-color: global.$primary-color;
        color: #fff;
    }

    .manage-btn {
        @include global.button-style;
        margin: 0;
        height: 4rem;
        color: global.$primary-color;
        border-color: global.$primary-color;
    }
}

.small-headed-panels {
    .campaign-panel {
        height: auto;
    }
}

.tier-pill {
    @include global.pill-style;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    min-width: 0rem;
    margin: 0;
    padding: 0 0.4rem;
}

.tier-badge {
    /* @include global.pill-style; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    margin: 0;
    padding: 0 0.4rem;

    img {
        width: 2.4rem;
    }
}

.bronze {
    border-color: global.$warning-dark-color;
    color: global.$warning-color;
    background-color: global.$warning-dark-color;
}

.silver {
    border-color: global.$dark-grey-3;
    color: global.$dark-grey-3;
    background-color: global.$mid-grey-1;
}

.gold {
    border-color: global.$warning-dark-color;
    background-color: global.$warning-color;
    color: global.$warning-dark-color;
}

.platinum {
    border-color: global.$dark-grey-3;
    color: global.$mid-grey-1;
    background-color: global.$mid-grey-2;
}

.campaigns-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    padding-bottom: 2rem;
    margin: 0 0 1rem;

    .live {
        border-color: global.$secondary-color;
    }

    .scheduled {
        border-color: global.$scheduled-color;
    }

    .ended {
        border-color: global.$mid-grey-1;
    }
}

.list-view-status {
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
}

.list-view {
    width: 100%;
    height: auto !important;

    button {
        width: 3rem;
        min-width: 3rem;
        max-width: 3rem;
        height: 100% !important;
        padding: 0.25rem 0.5rem !important;
    }

    .stream-count {
        width: 3rem;
        min-width: 3rem;
        max-width: 3rem;
        height: 100% !important;
        padding: 0.25rem 0.5rem !important;
        color: global.$dark-grey-2;
        border: solid 2px global.$light-grey-1;
        background-color: global.$light-grey-3;
    }

    .info-pill {
        padding: 0 0.5rem;
        gap: 0.25rem;
    }
}

.list-view-content {
    width: 100%;
    font-size: 1.16rem;
    font-weight: 600;
    /* white-space: nowrap; */

    button {
        width: 4.5rem;
        min-width: 4.5rem;
        max-width: 4.5rem;
        height: 100% !important;
        padding: 0.25rem 0.5rem !important;
        margin: 0;
    }

    .stream-count {
        @include global.button-style;
        margin: 0;
        width: 4.5rem;
        min-width: 4.5rem;
        max-width: 4.5rem;
        height: 100% !important;
        padding: 0.25rem 0.75rem !important;
        color: global.$dark-grey-2;
        border: solid 0 global.$light-grey-1;
        background-color: global.$light-grey-1;
    }

    .info-pill {
        padding: 0 0.5rem;
        gap: 0.25rem;
    }
}

.list-view-label {
    color: #000;
}

.list-view-data-block {
    width: 12%;
    max-width: 12%;
    min-width: 12%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
    font-weight: 400;
    gap: 0.5rem !important;

    svg {
        color: global.$light-text-color;
    }

    color: global.$primary-color;
}

.list-view-data-block.wide {
    width: 20%;
    max-width: 20%;
    min-width: 20%;
}

.list-view-data-block.narrow {
    width: 8%;
    max-width: 8%;
    min-width: 8%;
}
