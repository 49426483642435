@use '../../scss-fragments/global';

.create-campaign-progress-bar {
    @include global.unselectable;

    display: flex;
    flex-direction: row;
    gap: 0;

    height: 60px;
    margin: 1rem 0 2rem;
    font-weight: 600;

    .progress-block {
        display: flex;
        flex-direction: row;
        gap: 0;
        flex-grow: 1;

        height: 60px;
    }

    .block-mid {
        display: flex;
        align-items: center;
        justify-content: center;

        flex-grow: 1;
        min-width: fit-content;
        white-space: nowrap;

        border-style: solid;
        border-top-width: 2px;
        border-bottom-width: 2px;
        border-left-width: 0px;
        border-right-width: 0px;
    }

    .left-curve {
        border-style: solid;
        border-top-width: 2px;
        border-bottom-width: 2px;
        border-left-width: 2px;
        border-right-width: 0px;
        min-width: 30px;
        max-width: 30px;
        height: 60px;
        border-top-left-radius: 50em;
        border-bottom-left-radius: 50em;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .right-curve {
        border-style: solid;
        border-top-width: 2px;
        border-bottom-width: 2px;
        border-left-width: 0px;
        border-right-width: 2px;
        min-width: 30px;
        max-width: 30px;
        height: 60px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 50em;
        border-bottom-right-radius: 50em;
    }

    .completed {
        background-color: global.$off-white;
        border-color: global.$primary-color;
        color: global.$primary-color;
        svg {
            fill: global.$off-white;
            stroke: global.$primary-color;
        }
    }

    .in-progress {
        background-color: global.$primary-color;
        border-color: global.$primary-color;
        color: global.$off-white;
        svg {
            fill: global.$primary-color;
            stroke: global.$primary-color;
        }
    }

    .not-started {
        background-color: global.$body-bg-color;
        border-color: global.$mid-grey-2;
        color: global.$mid-grey-1;
        svg {
            fill: global.$body-bg-color;
            stroke: global.$mid-grey-2;
        }
    }

    .end {
        background-color: transparent;
    }

    .between {
        margin-left: -6px;
    }
}
