@use "../../scss-fragments/global";
@use "../../scss-fragments/forms";

.register-panel-holder {
    display: flex;
    align-content: center;
}

.register-panel {
    @include global.panel-style;
    @include global.panel-shadow;
    position: relative;
    width: 90%;
    max-width: 450px;
    margin: auto;

    max-height: 100vh;

    h2 {
        margin-bottom: 2rem;
    }

    .submit-btn {
        margin-bottom: 2rem;
    }

    .close-btn {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 3rem;
        height: 3rem;
        border-radius:100em;
        border-width: 2px;
        border-style: solid;
        border-color: global.$twitch-purple;
        color: global.$twitch-purple;

        cursor: pointer;

        .icon {
            height: 1.2rem;
        }
    }
}
