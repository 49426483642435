@use "../../../../scss-fragments/global";

.one-live-message-status {
    position: relative;

    border: 1px solid global.$twitch-purple;
    background-color: global.$primary-light-color;
    border-radius: 0.5rem;
    padding: 0.5rem;

    color: global.$body-copy-color;

    &.response {
        margin-left: 1.5rem;
        border-color: global.$success-dark-color;
        background-color: global.$success-light-color;
    }

    .dark-text {
        color: global.$body-copy-color;
    }

    .small-lt-control-btn {
        font-size: 1rem;
        padding: 0.25rem 1rem;
        border: 0px solid transparent;
        border-radius: 0.5rem;
        background-color: global.$primary-color;
        color: #fff;
        text-align: center;

        &:hover {
            cursor: pointer;
        }

        &.red {
            background-color: global.$error-dark-color;
        }
        &.green {
            background-color: global.$success-dark-color;
        }
        &.yellow {
            background-color: global.$warning-dark-color;
        }
        &.grey {
            background-color: global.$dark-grey-3;

            &:hover {
                cursor: not-allowed;
            }
        }
    }
}

.lt-mess-list-box {
    max-height: 550px;
    overflow-y: scroll;
    padding-right: 0.5rem;
}
