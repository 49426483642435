@use "../../../scss-fragments/global";
@use "../../../scss-fragments/forms";

.audio-upload-holder {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0; /* 0 2rem;*/
    padding: 0;

    text-align: left;
    font-weight: 600;

    .label {
        font-weight: 600;
        margin-bottom: 0;
        line-height: 1.2rem;
    }

    .compact {
        gap: 0.2rem !important;
    }

    .play-button {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 4rem;
        height: 4rem;

        border: 2px solid global.$primary-color;
        color: global.$primary-color;
        border-radius: 50em;

        cursor: pointer;
    }

    .btn-off {
        border-color: global.$mid-grey-2;
        color: global.$mid-grey-2;
    }

    .remove-btn {
        color: global.$primary-color;
        cursor: pointer;
    }

    .upload-btn {
        color: global.$primary-color;
        cursor: pointer;
    }

    .file-name-holder {
        max-width: 100%;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    audio {
        visibility: hidden;
        width: 0px;
        height: 0px;
        overflow: hidden;
        margin: 0;
        padding: 0;
    }
}
