@use "../../scss-fragments/global";

.edit-user-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;

    & h2 {
        margin-bottom: 1rem;
    }

    .s-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 2rem;
        row-gap: 0;
    }

    .column-cell {
        display: flex;
        flex-direction: column;
    }

    .no-gap {
        gap: 0;
    }

    .small-gap {
        gap: 1rem;
    }

    .manage-btn {
        @include global.button-style;
        margin: 0;
        height: 4rem;
        color: global.$primary-color;
        border-color: global.$primary-color;
    }
    .s-profile-pic {
        position: relative;
        flex-shrink: 1;

        .icon-overlay {
            position: absolute;
            right: 0.6rem;
            bottom: 0.6rem;
            width: 2rem;
            height: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .pp-style {
        border-color: global.$mid-grey-2;
    }

    .s-edit-icon {
        color: global.$primary-color;
        cursor: pointer;
    }

    .pp-edit {
        background-color: #FFF;
        border: 0px solid global.$primary-color;
        border-radius: 50em;
    }

    .tw-ppic-icon {
        width: 100%;
    }
}
