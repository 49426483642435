.unauth-content {
    margin-top: 1rem;

    & p {
        margin-bottom: 2rem;
    }

    .submit-btn {
        margin-bottom: 1rem !important;
    }
}
