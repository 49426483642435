@use 'scss-fragments/global';

body {
  margin: 0;
  font-family: PrimaryFont, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  margin:0;
	padding:0;
	background-color: rgba(0, 0, 0, 0);
  color: global.$body-copy-color;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.test-panel {
  @include global.panel-style;
  border-color: global.$primary-color;
  background-image: global.$gradient-primary;
  width: 300px;
  height: 300px;
}

.test-pill {
  @include global.pill-style;
  border-color: global.$primary-color;
  color: global.$primary-color;
  background-color: global.$input-bg-color;
  width: 200px;
}

.test-button {
  @include global.button-style;
  border-color: global.$primary-color;
  background-color: global.$primary-color;
  color: #FFF;
  width: 180px;
  height: 80px;
}
