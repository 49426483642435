@use '../../scss-fragments/global';
@use "../../scss-fragments/forms";

.create-campaign-content {
    position: fixed;
    left: 0;
    top: 4rem;
    width: 100vw;
    height: calc(100vh - 4rem);
    background-image: global.$gradient-secondary;

    display: flex;
    align-items: center;
    justify-content: center;

    .search-box {
        min-width: 22rem;
        max-width: 22rem;
    }

    .remove-option {
        font-weight: 600;
        color: global.$primary-color;
        cursor: pointer;

        svg {
            margin-bottom: 1rem;
        }
    }

    .content-panel {
        @include global.panel-style;
        @include global.panel-shadow;

        display: flex;
        flex-direction: column;
        flex-grow: 1;

        height: 100%;
        min-height: 640px;
        max-height: 90%;
        min-width: 840px;
        max-width: 70%;
    }
    .split-content {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        gap: 1rem;

        .pane {
            width: 50%;
            flex-grow: 1;
        }
    }

    .gap {
        height: 1.5rem;
        max-height: 1.5rem;
        min-height: 1.5rem;
    }

    .footer-gap {
        height: 5.5rem;
        min-height: 5.5rem;
        max-height: 5.5rem;
    }

    .scroll-panel {
        @include global.panel-style;
        width: 100%;
        height: 100%;
        max-height: calc(100vh - 34rem);
        background-color: global.$light-grey-2;

        p {
            margin-bottom: 1rem;
        }
    }

    .scroll-container {
        width: 100%;
        height: calc(100% - 3rem);
        /*max-height: calc(100vh - 40rem);*/
        overflow-y: scroll;
        padding-right: 1rem;

        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .scroll-container-p {
        width: 100%;
        max-height: calc(100vh - 34rem);
        overflow-y: auto;
        padding-right: 1rem;
    }

    .scroll-container-r {
        width: 100%;
        max-height: calc(100vh - 30rem);
        overflow-y: auto;
        padding-right: 1rem;
    }

    .restrict-width {
        max-width: 50%;
        min-width: 400px;
    }

    .drop-down-closed {
        height: 3rem;
        margin-bottom: 1.4rem;
    }

    .continue-btn {
        max-width: 25%;
        position: absolute;
        right: 2rem;
        bottom: 0;
    }

    .profile-pic {
        margin-left: 2px;
    }

    .user-name {
        font-weight: 500;
    }


/* .item must be first */
.pagination-item {
    align-items: center;
    color: #333;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 40px;
    justify-content: center;
    width: 40px;
  }
  
  .disabled-page {
    color: #333333;
  }
  
  .item-active {
    border-radius: 40px;
    background-color: #9146ff;
    color: #FFFFFF;
  }
  
  .break-me {
  }
  
  .pagination-next {
    border-left: solid 1px #808e9b;
    font-size: 4px;
    height: 60px;
    position: absolute;
    right: 0;
    width: 150px;
  }
  
  .pagination {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: center;
    list-style-type: none;
    position: relative;
    width: 100%;
  }
  
  .pagination-page {
    font-weight: 700;
  }
  
  .pagination-previous {
    border-right: solid 1px #808e9b;
    font-size: 4px;
    height: 60px;
    left: 0;
    position: absolute;
    width: 150px;
  }
  

}

.twitch-link-icon {
    svg {
        margin: 0 0.2rem 0 0.5rem;
    }
}
