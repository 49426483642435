@use "../../../scss-fragments/global";
@use "../../../scss-fragments/forms";

.search-box {
    @include forms.form-field-holder;

    margin: 0;
    position: relative;
    width: 100%;

    input {
        @include forms.form-field-style;
        background-color: #FFF;
        width: 100%;
        margin: 0;
        padding-left: 3rem;
    }

    input:focus {
        outline-color: global.$primary-color;
        background-color: #FFF;
    }

    .icon {
        color: global.$mid-grey-1;
        position: absolute;
        left: 1rem;
        top: 1rem;
    }
}

.top-search .search-box {
    min-width: 22rem;
    max-width: 22rem;
}
