@use "../../../scss-fragments/global";
@use "../../../scss-fragments/forms";


.check-box-content {
    display: flex;
        flex-grow: 1;
        flex-direction: column;
}

.check-box-holder {
    @include forms.form-field-holder;

    cursor: pointer;

    .check-box-outer {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 3rem;
    }

    .check-box {
        @include global.standard-rollover-transition;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50em;
        border-width: 2px;
        border-style: solid;

        .icon {
            height: 1rem;
        }
    }

    .checked {
        border-color: global.$success-color;
        background-color: global.$success-color;
        color: global.$success-dark-color;
    }

    .unchecked {
        border-color: global.$mid-grey-2;
        background-color: global.$mid-grey-2;
        color: global.$mid-grey-2;
    }

    .label {
        display: flex;
        flex-grow: 1;

        margin: 0 0 0 0.5rem;
        color: global.$dark-grey-3;
        font-weight: 500;
        font-size: 0.9rem;
    }

    &.unclickable {
        cursor: default;
    }
}
