@use "../../scss-fragments/global";

.profile-pic {
    display: flex;
    margin: 0.5rem;
    border-radius: 50em;
    border-color: global.$body-bg-color;
    border-style: solid;
    outline-style: solid;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.large {
        border-width: 3px;
        outline-width: 2px;
        width: 3.5rem;
        height: 3.5rem;
    }

    &.medium {
        border-width: 3px;
        outline-width: 2px;
        width: 2.8rem;
        height: 2.8rem;
    }

    &.small {
        border-width: 2px;
        outline-width: 2px;
        width: 1.8rem;
        height: 1.8rem;
    }

    &.extralarge {
        border-width: 5px;
        outline-width: 3px;
        width: 8.5rem;
        height: 8.5rem;
    }
}


