@use "../../scss-fragments/global";

.screen-recorder-top-bar-icon {
    margin: 0;
    padding: 0;
    color: global.$primary-color;
}
.screen-recorder-top-bar-icon.active {
    color: global.$error-dark-color;
    animation: fade-in-out 1s infinite;
}
@keyframes fade-in-out {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.screen-recorder-pop-container {
    @include global.button-style;
    @include global.panel-shadow;
    @include global.standard-rollover-transition;

    display: block;
    position: fixed;
    right: 0rem;
    top: 4rem;
    z-index: 1000;

    text-align: left;

    width: 36rem;
    margin: 0.5rem;
    padding: 0.5rem;

    background-color: global.$dark-grey-2;
    border-color: global.$dark-grey-2;
    color: global.$mid-grey-1;

    transform: scale(1);
    transform-origin: 100% 0;

    button {
        @include global.button-style;

        margin: auto;
        width: 100%;
        height: 3rem;

        background-color: global.$dark-grey-2;
        color: global.$mid-grey-1;
        border-color: global.$mid-grey-1;

        transition: all 0.1s;

        &:hover {
            color: global.$light-grey-1;
            background-color: global.$primary-color;
            border-color: global.$primary-color;
        }

        &.disabled {
            color: global.$dark-grey-1;
            background-color: global.$dark-grey-2;
            border-color: global.$dark-grey-1;
            cursor: not-allowed;
        }

        .icon {
            margin-right: 0.5rem;

            &.red {
                color: global.$error-color;
            }
            &.dark {
                color: global.$dark-grey-1;
            }
        }
    }

    a {
        text-decoration: none;
    }

    &.hidden {
        transform: scale(0);
    }

    .title {
        color: global.$off-white;
        font-size: 1.5rem;
        line-height: 1.8rem;
        margin: 0;
    }

    .copy {
        color: global.$mid-grey-2;
        font-weight: 400;
        font-size: 1rem;
        margin: 0;
    }

    .recording-preview {
        width: 100%;
        background-color: global.$dark-grey-1;
        border: 0px solid global.$dark-grey-1;
        border-radius: 0.5rem;
        overflow: hidden;

        video {
            width: 100%;
            height: 100%;
            margin-bottom: -5px;
        }
        
        &.hidden {
            display: none;
        }

        p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: global.$mid-grey-2;
            font-weight: 400;
            font-size: 1rem;
            margin: 0;
            white-space: nowrap;
        }
    }
}
