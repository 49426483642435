@use '../../scss-fragments/global';

.campaign-main-content {
    /* padding-right: 1rem !important; */
    display: flex;
    flex-direction: column;
}

.campaigns-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    padding-bottom: 0 !important;
    margin: 0 !important;

    .live {
        border-color: global.$secondary-color;
    }

    .scheduled {
        border-color: global.$scheduled-color;
    }

    .ended {
        border-color: global.$mid-grey-1;
    }

    hr {
        width: 100%;
    }

    .clist-headings-container {
        margin: 0 0 1rem;
        padding: 1rem 3.5rem 0.5rem 4.5rem;
        border-bottom: solid 1px global.$mid-grey-2;

        .list-view-content {
            font-size: 1rem;
            font-weight: 600;
            color: #A0A3BD;
        }

        .list-view-data-block {
            font-size: 1rem;
            font-weight: 600;
            color: #A0A3BD;
        }

        .left-pad {
            padding: 0 0 0 4rem;
        }

        .right-pad {
            padding: 0 9rem 0 0;
        }
    }

    .scroll-container {
        padding-right: 1rem;

        &::-webkit-scrollbar-track {
            margin-top: 0.5rem !important;
        }
    }

    .scroll-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        height: calc(100vh - 40rem) !important;
    }

    .scroll-panels {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2rem;

        height: calc(100vh - 30rem) !important;
    }
}


.create-campaign-button {
    @include global.unselectable;
    @include global.panel-style;
    @include global.panel-shadow;

    margin: 0;
    width: 25rem;
    height: 25rem;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* background-color: global.$primary-color; */
    background-image:linear-gradient(to bottom right, #FA26CD, #FAEE25);
   /* border-color: global.$primary-color;*/
    color: #FFF;

    h3 {
        color: #FFF;
    }
}

.wide-btn {
    width: 100%;
    height: 5rem;
    white-space: nowrap;
    border-radius: 10rem;

    align-items: left;
    justify-content: left;
    text-align: left;

    h3 {
        margin: 0;
    }
}

.switch-view-btn {
    @include global.standard-rollover-transition;

    border: 2px solid global.$primary-color;
    border-radius: 0.3rem;
    background-color: #FFF;
    color: global.$primary-color;

    cursor: pointer;

    display: flex;
    align-items: center;
    padding: 0.2rem 0.5rem;

    &:hover {
        color: #FFF;
        background-color: global.$primary-color;
        border-color: global.$primary-color;
    }
}

.view-selected {
    cursor: default;

    color: #FFF;
    background-color: global.$primary-color;
    border-color: global.$primary-color;

    &:hover {
        color: #FFF;
        background-color: global.$primary-color;
    }
}




/* .item must be first */
.pagination-item {
    align-items: center;
    color: #333;
    display: flex;
    font-size: 14px;
    height: 40px;
    justify-content: center;
    width: 40px;

    a {
        color: #333333;
    }
  }
  
  .disabled-page {
    color: #333333;
    /* cursor: pointer; */
    [role=button] {
        cursor: default !important;
    }

    a {
        color: #999;
    }

    a:hover {
        color: #999;
    }

    a:active {
        color: #999;
    }
  }
  
  .item-active {
    border-radius: 40px;
    background-color: #9146ff;
    color: #FFFFFF;
    [role=button] {
        cursor: default !important;
    }
  }
  
  .break-me {
  }
  
  .pagination-next {
    border-left: solid 1px #808e9b;
    font-size: 4px;
    height: 60px;
    position: absolute;
    right: 0;
    width: 150px;
  }
  
  .pagination {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: center;
    list-style-type: none;
    position: relative;
    width: 100%;
  }
  
  .pagination-page {
    font-weight: 700;
  }
  
  .pagination-previous {
    border-right: solid 1px #808e9b;
    font-size: 4px;
    height: 60px;
    left: 0;
    position: absolute;
    width: 150px;
  }
  
