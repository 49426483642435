@use "../../../scss-fragments/global";
@use "../../../scss-fragments/forms";

.c-hex-field {
    width: 8rem;
    min-width: 8rem;
    max-width: 8rem;
}

.current-color {
    width: 3rem;
    height: 3rem;
    border: solid 2px global.$mid-grey-1;
    border-radius: 0.5rem;
}

.color-picker-holder {
    position: absolute;
    left: 0;
    bottom: 4rem;
    z-index: 1000;
}

.color-pop {
    max-width: fit-content;
    padding-left: 6rem;
    padding-right: 6rem;

    h2 {
        text-align: center;
    }

    .var-label {
        text-align: center;
        font-weight: 700;
        font-size: 1.2rem;
    }
}
