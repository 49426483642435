@use "../../scss-fragments/global";
@use "../../scss-fragments/forms";

.customisation-controls-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem 2rem;
    padding: 0;
    margin: 0;
    width: 100%;

    .disabled-control {
        /* pointer-events: none; */
    }

    .theme-check {
        position: absolute;
        z-index: 1000000;
        padding: 0;
        margin: 0;
        left: -0.5rem;
        top: -0.5rem;
        transform: scale(0.65);
    }

    .purple {
        color: global.$primary-color;
    }

    .headed-panel {
        margin-bottom: 0 !important;
    }

    .advanced-highlight {
        border-width: 2px;
        border-style: solid;
        border-color: global.$error-bg-color !important;
        /* background-color: global.$error-light-color; */
        padding: 0.5rem;
    }
    .display-conditions-highlight {
        border-width: 2px;
        border-style: solid;
        border-color: global.$warning-color;
        background-color: global.$warning-light-color !important;
        padding: 0.5rem;
    }
    .node-highlight {
        box-shadow: 0 0 5px 1px global.$primary-color;
    }
    .undefined-highlight {
        opacity: 0.5 !important;
    }

    .group-box {
        border-radius: global.$panel-border-radius;
        border-width: 0px;
        border-style: solid;
        background-color: global.$light-grey-1;
        padding: 2rem;
        min-width: fit-content;
    }

    .group-outline-box {
        border-radius: global.$panel-border-radius;
        border-width: 1px;
        border-style: solid;
        background-color: none;
        border-color: global.$mid-grey-2;
        padding: 2rem;
        min-width: fit-content;
    }

    .group-width {
        width: fit-content;
        min-width: calc(50% - 1rem);
        max-width: fit-content;
    }

    .fill-width {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }

    .shrink-width {
        width: fit-content;
        min-width: fit-content;
        max-width: fit-content;
    }

    .header-group {
        h4 {
            color: global.$primary-color;
        }

        color: global.$primary-color;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        padding-bottom: 0.5rem;
        margin-bottom: 0;
        border-bottom: 1px solid global.$primary-color;
    }

    .group-icon {
        svg {
            color: global.$mid-grey-1;
        }
    
        justify-content: center;
        align-content: center;
        display: flex;
        margin-bottom: 0.5rem;
    }

    .control-group {
        /* @include global.panel-style; */
        display: flex;
        flex-direction: row;
        flex: 1;
        flex-wrap: wrap;
        gap: 0 3rem; // 1rem;
        margin: 0 0 -1rem;
        padding: 0;
        border-width: 0;
        /* background-color: global.$light-grey-1; */
        width: 100%;
        /* min-width: 36rem;
        max-width: 36rem; */
        /* min-height: 10rem; */
        max-height: fit-content;

        .wide-select {
            width: 100%;
        }

        .add-button {
            width: 2rem;
            height: 2rem;
        }

        .square-add-button {
            width: 8rem;
            height: 8rem;
            border-radius: 1rem;
            border-width: 2px;
            border-style: dotted;
            border-color: rgb(188, 164, 255);
            background-color: #eff0f6;
        }

        .add-button-inactive {
            border-color: global.$light-text-color;
            color: global.$light-text-color;
        }

        .var-label {
            font-weight: 700;
            font-size: 1.2rem;
        }

        .var-spacer {
            min-height: 1.5rem;
            max-height: 1.5rem;
            height: 1.5rem;
        }

        .var-spacer-line {
            border-top: 1px solid #DDD;
            min-height: 1.5rem;
            max-height: 1.5rem;
            height: 1.5rem;
            margin-top: 0.5rem;
        }
    }

    .theme-control {
        .label {
            margin-left: 2.5rem !important;
        }
    }

    .cc-group-holder {
        /* @include global.standard-rollover-transition; */
        overflow: hidden;
        height: 0px;
        opacity: 0;
        margin-bottom: 0;
    }

    .cc-var-group-hidden {
        /* @include global.standard-rollover-transition; */
        overflow: hidden;
        height: 0px;
        max-height: 0px;
        width: 0px;
        max-width: 0px;
        opacity: 0;
    }

    .customisation-control-holder {
        /* @include global.standard-rollover-transition; */
        display: block;
        overflow: hidden;
        height: 0px;
        opacity: 0;
        margin-bottom: 0;
    }

    .cc-expanded {
        /* @include global.standard-rollover-transition; */
        overflow: visible;
        height: fit-content;
        opacity: 1;
        margin-bottom: 1rem;
    }

    .column-breaks {
        gap: 3rem;
    }

    .cc-column {
        display: flex;
        flex-direction: column;
        gap: 0;
        position: relative;
        flex-grow: 1;
        width: 100%;

        .del-icon {
            position: absolute;
            top: -1rem;
            right: -1rem;

            display: flex;
            align-items: center;
            justify-content: center;

            width: 2rem;
            height: 2rem;
            
            border: solid 0px #000;
            border-radius: 50%;
            background-color: global.$primary-color;
            color: #FFF;

            cursor: pointer;
        }

        .copy-icon {
            position: absolute;
            top: -1rem;
            right: 1.2rem;

            display: flex;
            align-items: center;
            justify-content: center;

            width: 2rem;
            height: 2rem;
            
            border: solid 0px #000;
            border-radius: 50%;
            background-color: global.$primary-color;
            color: #FFF;

            cursor: pointer;
        }
    }

    .button-grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
        margin: 0 0 1.5rem;
        padding: 0;
        width: 100%;

        button {
            min-width: 30%;
            width: fit-content;
            display: flex;
            flex: 1;
            margin: 0;
        }
    }

    .more-info {
        /* margin-top: 2rem; */
        align-self: left;
        color: global.$primary-color;
    }

    .more-info-2 {
        margin-top: 0.5rem;
        align-self: left;
        color: global.$primary-color;
    }

    .end {
        margin-top: 0;
        align-self: flex-end;
    }

    .start {
        margin-top: 0;
        align-self: flex-start;
    }

    .font-val-holder {
        @include forms.form-field-style;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .collapsible-control-section-holder {
        margin-bottom: 1rem;
    }

    .collapsible-control-section {
        padding: 1rem;
        background-color: rgba(0, 0, 0, 0.03);
        border: 1px solid global.$mid-grey-2;
        border-top-width: 0px;
        border-radius: 0px 0px 0.5rem 0.5rem;
    }
}

.customisation-control-header {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-grow: 1;

    color: #FFF;

    h4 {
        color: #FFF !important;
    }
}

.h-btn {
    color: global.$primary-color;
}

.disruptor-column {
    display: flex;
    flex-direction: column;
    gap: 0;
    justify-content: center;
    width: 100%;
}
