@use "../../scss-fragments/global";

.left-color-block-box {
    display: flex;
    flex-direction: row;

    padding: 0;
    margin: 0;

    font-weight: 500;

    .content-block {
        @include global.standard-rollover-transition;
        padding: 1rem;
        min-height: 5rem;
    }

    .left {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 4.5rem;
        max-width: 4.5rem;
        min-width: 4.5rem;

        /* color: #FFF; */

        border-radius: 1rem 0 0 1rem;
        border-width: 0;
        border-style: solid;
    }

    .white-icon {
        color: #FFF !important;
    }

    .user-profile-pic {

        max-width: 3rem;
        max-height: 3rem;
        border-radius: 50rem;
        border: 3px solid white;
    }

    .right {
        position: relative;

        flex-grow: 1;

        display: flex;
        flex-direction: row;

        border-radius:  0 1rem 1rem 0;
        border-width: 2px;
        border-style: solid;

        background-color: global.$off-white;

        .bot-content {
            color: global.$primary-color;
        }

        h4 {
            color: currentColor;
            margin: 0;
        }
    }
}

.lcb-heading {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.lcb-headed-panel-holder {
    display: flex;
    flex-direction: column;
    gap: 0;

    margin: 0;
    padding: 0;
}

.lcb-headed-content {
    @include global.panel-style;
    @include global.standard-rollover-transition;

    border: solid 2px global.$mid-grey-2;
    border-top-width: 0;
    border-radius: 0 0 1rem 1rem;
    background-color: #FFF;
       
    margin: 0;
    min-height: calc(100% - 5.2rem - 4px);

    display: flex;
    flex-direction: column;
    gap: 0.1rem;

    color: #A0A3BD;

    p {
        margin: 0 0 0.5rem 0;
    }

    h3 {
        margin: 0.5rem;
    }

    h4 {
        margin: 0 0 0.4rem;
    }

    h5 {
        color:#6E7191;
    }
}

.lcb-full-width {
    width: 100%;
}

.lcb-button-icon {
    svg {
        max-width: 1.4rem;
        max-height: 1.4rem;
    }
}

.lcb-black-right {
    border-color: #000;
    color: #000;
}

.lcb-black-left {
    background-color: #000;
    color: #FFF;
}

.lcb-grey-right {
    border-color: global.$dark-grey-3;
    color: global.$dark-grey-3;
}

.lcb-grey-left {
    background-color: global.$dark-grey-3;
    color: global.$mid-grey-2;
}

.lcb-light-grey-right {
    border-color: global.$mid-grey-2;
    color: global.$mid-grey-1;
}

.lcb-light-grey-left {
    background-color: global.$mid-grey-2;
    color: global.$mid-grey-1;
}

.lcb-light-green-right {
    border-color: #5DE8A0;
    color: #45B97D;
}

.lcb-light-green-left {
    background-color: #5DE8A0;
    color: #FFF;
}

.lcb-light-yellow-right {
    border-color: #FFD789;
    color: #C67F00;
}

.lcb-light-yellow-left {
    background-color: #FFD789;
    color: #C67F00;
}

.lcb-light-purple-right {
    border-color: #BCA4FF;
    color: #9146FF;
}

.lcb-light-purple-left {
    background-color: #BCA4FF;
    color: #9146FF;
}

.lcb-light-blue-right {
    border-color: #82E9FF;
    color: #1CC8EE;
}

.lcb-light-blue-left {
    background-color: #82E9FF;
    color: #1CC8EE;
}

.lcb-dark-red-right {
    border-color: #C30052;
    color: #990041;
}

.lcb-dark-red-left {
    background-color: #C30052;
    color: #990041;
}

.lcb-light-red-right {
    border-color: global.$error-bg-color;
    color: global.$error-color;
}

.lcb-light-red-left {
    background-color: global.$error-bg-color;
    color: global.$error-color;
}

.lcb-dark-green-right {
    border-color: #00966D;
    color: #007656;
}

.lcb-dark-green-left {
    background-color: #00966D;
    color: #007656;
}

.lcb-dark-green-right {
    border-color: #00966D;
    color: #007656;
}

.lcb-dark-green-left {
    background-color: #00966D;
    color: #007656;
}

.lcb-manual-bot-right {
    border-color: #BCA4FF;
    color: global.$primary-color;
}

.lcb-manual-bot-left {
    background-color: #BCA4FF;
    color: #FFF;
}

.lcb-auto-bot-right {
    border-color: #BC89E8;
    color: global.$primary-color;
}

.lcb-auto-bot-left {
    background-color: #BC89E8;
    color: #FFF;
}

.lcb-response-bot-right {
    border-color: #EB96FF;
    color: global.$primary-color;
}

.lcb-response-bot-left {
    background-color: #EB96FF;
    color: #FFF;
}

.lcb-interval-bot-right {
    border-color: #8989E8;
    color: global.$primary-color;
}

.lcb-interval-bot-left {
    background-color: #8989E8;
    color: #FFF;
}

.lcb-image-disruptor-right {
    border-color: #E332C7;
    color: global.$primary-color;
}

.lcb-image-disruptor-left {
    background-color: #E332C7;
    color: #FFF;
}

.lcb-video-disruptor-right {
    border-color: #EC6624;
    color: global.$primary-color;
}

.lcb-video-disruptor-left {
    background-color: #EC6624;
    color: #FFF;
}

.lcb-audio-disruptor-right {
    border-color: #00966D;
    color: global.$primary-color;
}

.lcb-audio-disruptor-left {
    background-color: #00966D;
    color: #FFF;
}

.lcb-mini-feature-disruptor-right {
    border-color: #2B5DF7;
    color: global.$primary-color;
}



.lcb-mini-feature-disruptor-left {
    background-color: #2B5DF7;
    color: #FFF;
}


.lcb-twitch-left {
    background-color: #9146FF;
    color: #fff;
}
.lcb-twitch-right {
    border-color: #9146FF;
    color: global.$primary-color;
}

.lcb-feature-default-right {
    border-color: #4DBEA6;
    color: #00966D;
}

.lcb-feature-default-left {
    background-color: #4DBEA6;
    color: #FFF;
}

.lcb-total-interactions-right {
    border-color: #FFD789;
    color: #C67F00;
}

.lcb-total-interactions-left {
    background-color: #FFD789;
    color: #FFF;
}

.lcb-bot-default-right {
    border-color: #BCA4FF;
    color: global.$light-text-color;
}

.lcb-bot-default-left {
    background-color: #BCA4FF;
    color: #FFF;
}

.lcb-disruptor-default-right {
    border-color: #82E9FF;
    color: #1CC8EE;
}

.lcb-disruptor-default-left {
    background-color: #82E9FF;
    color: #FFF;
}

.lcb-an-messages-right {
    border-color: #FF9866;
    color: #C67F00;
}

.lcb-an-messages-left {
    background-color: #FF9866;
    color: #FFF;
}

.lcb-brand-engagement-right {
    border-color: #F87CA8;
    color: global.$light-text-color;
}

.lcb-brand-engagement-left {
    background-color: #F87CA8;
    color: #FFF;
}

.lcb-u-users-right {
    border-color: #5DE8A0;
    color: global.$light-text-color;
}

.lcb-u-users-left {
    background-color: #5DE8A0;
    color: #FFF;
}

.lcb-live-mode-right {
    border-color: global.$success-color;
    background-color: global.$success-color !important;
    color: #FFF;
}

.lcb-live-mode-left {
    background-color: global.$success-color;
    color: #FFF;
}

.lcb-preview-mode-right {
    border-color: global.$warning-color;
    background-color: global.$warning-color !important;
    color: #FFF;
}

.lcb-preview-mode-left {
    background-color: global.$warning-color;
    color: #FFF;
}

.lcb-disabled-mode-right {
    border-color: global.$mid-grey-2;
    background-color: global.$mid-grey-2 !important;
    color: global.$mid-grey-1;
}

.lcb-disabled-mode-left {
    background-color: global.$mid-grey-2;
    color: #FFF;
}

.lcb-warning-right {
    border-color: #C30052;
    color: global.$light-text-color;
    h4 {
        color: #C30052 !important;
    }
}

.lcb-warning-left {
    background-color: #C30052;
    color: #FFF;
}

.lcb-quiz-left {
    background-color: #FFB31A;
    color: #FFF;
}

.lcb-quiz-right {
    border-color: #FFB31A;
    color: global.$primary-color;
}

.lcb-quiz-list-left {
    background-color: #FFB31A;
    color: #FFF;
}

.lcb-quiz-list-right {
    border-color: #FFB31A;
    color: #FFB31A;
}

.lcb-leaderboard-left {
    background-color: #47B2DE;
    color: #FFF;
}

.lcb-leaderboard-right {
    border-color: #47B2DE;
    color: global.$primary-color;
}

.lcb-leaderboard-list-left {
    background-color: #47B2DE;
    color: #FFF;
}

.lcb-leaderboard-list-right {
    border-color: #47B2DE;
    color: #47B2DE;
}
