@use '../../scss-fragments/global';

.analytics-page-panel {
    @include global.panel-style;
    @include global.panel-shadow;
    margin: 0;
    padding-bottom: 0;

    position: relative;

    width: 100%;
    height: 100%;
    max-height: calc(100% - 2rem);
    flex-grow: 1;
}

.an-fullcol-scroll-area {
    height: calc(100vh - 24rem);
    min-height: calc(100vh - 24rem);
    max-height: calc(100vh - 24rem);
    /* padding-bottom: 1rem; */
    padding-right: 1rem;
    overflow-y: scroll;
}

.analytics-section {
    margin-bottom: 3rem;
}

.analytics-sub-section {
    margin-bottom: 1.5rem;
}

.analytics-summary-content {
    color: #A0A3BD;
    h1, h2, h3, h4, h5 { color: #6E7191 !important; }
}

.ana-data-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin: 0;

    h4 {
        margin: 0;
    }

    h5 {
        margin: 0;
    }

    p {
        margin: 0;
    }

    .small-text {
        margin: 0;
    }

    .align-bottom {
        align-self: end;
    }

    font-weight: 500;
}

.viewer-counts {
    gap: 8rem;
}

.circle-color-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    border-radius:  100em;
    border-width: 0;
    border-style: solid;

    padding: 0;
    margin: 0;

    color: #FFF;

    width: 2.5rem;
    max-width: 2.5rem;
    min-width: 2.5rem;

    height: 2.5rem;
    max-height: 2.5rem;
    min-height: 2.5rem;
}

.an-mxi-key {
    margin-right: 2rem;
}

.an-mxi-drop {
    min-width: 7rem;
}

.an-tutype-drop {
    min-width: 11rem;
}

.an-po {
    font-weight: 600;
}

.an-po-list {
    gap: 0 !important;
}

.an-po-timeline-left {
    width: 3rem;
    height: 4rem;
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: center;
    justify-content: center;
    background-image: url(../../assets/po-line.svg);
    background-position-x: center;
    background-repeat: repeat-y;
}

.first-po-item {
    background-position-y: calc(2rem + 5px);
    background-repeat: no-repeat;
}

.only-po-item {
    background: none;
}

.last-po-item {
    align-items: center;
    background: none;
}

.last-po-item-line {
    height: 19px;
    width: 1px;
}

.an-po-timeline-left-divider {
    width: 3rem;
    height: 2rem;
    background-image: url(../../assets/po-line.svg);
    background-repeat: repeat-y;
    background-position-x: center;
}

.an-po-time {
    gap: 2rem !important;
}

.an-pie-key {
    align-items: center;
    justify-content: center;
    font-weight: 500;
    text-align: center;

    h5 {
        margin: 0;
    }
}

.an-user-list-item {
    border-radius: global.$button-border-radius;
    border-width: 0;
    border-style: solid;

    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem;

    width: 100%;

    font-weight: 600;

    color: #9146FF;
    .black-text {
        color: #000;
    }

    cursor: pointer;
}

.an-user-light {
    background-color: #F3EFFF;
}

.an-user-dark {
    background-color: #E4DAFF;
}

.an-light-green-bd {
    border-color: #5DE8A0;
    color: #45B97D;
}

.an-light-green-bg {
    background-color: #5DE8A0;
}

.an-light-yellow-bd {
    border-color: #FFD789;
    color: #C67F00;
}

.an-light-yellow-bg {
    background-color: #FFD789;
}

.an-light-purple-bd {
    border-color: #BCA4FF;
    color: #9146FF;
}
.an-light-yellow-bg {
    background-color: #FFD581;
}
.an-light-purple-bg {
    background-color: #BCA4FF;
}

.an-light-blue-bd {
    border-color: #82E9FF;
    color: #1CC8EE;
}

.an-light-blue-bg {
    background-color: #ea80aa;
}

.an-dark-red-bd {
    border-color: #C30052;
    color: #990041;
}

.an-dark-red-bg {
    background-color: #C30052;
}

.an-dark-green-bd {
    border-color: #00966D;
    color: #007656;
}

.an-dark-green-bg {
    background-color: #00966D;
}

.an-light-grey-bg {
    background-color: #D9DBE9;
}

.an-dark-blue {
    color: #2B5DF7;
}

.an-pink {
    color: #E332C7;
}

.an-dark-red {
    color: #A61F6B;
}

.an-dark-orange {
    color: #EC6624
}

.an-dark-green {
    color: #4DBEA6;
}

.an-purple {
    color: #9146FF;
}

.an-image-disruptor-color {
    color: #E332C7;
}

.an-video-disruptor-color {
    color: #EC6624;
}

.an-mini-feature-disruptor-color {
    color: #2B5DF7;
}



.an-audio-disruptor-color {
    color: #00966D;
}

.an-feature-color {
    color: #4DBEA6;
}

.an-disruptor-color {
    color: #1CC8EE;
}
