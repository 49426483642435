@use '../../scss-fragments/global';

.streamfog-test-page-panel {
    @include global.panel-style;
    @include global.panel-shadow;
    margin: 0;
    padding-bottom: 0;

    position: relative;

    width: 100%;
    height: 100%;
    max-height: calc(100% - 2rem);
    flex-grow: 1;

    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
}
