@use "../../scss-fragments/global";

.round-title-holder {
  display: flex;
  flex-direction: column;
  gap: 0;
  .small-text {
    margin-bottom: 0 !important;
  }
}

.round-title {
  font-size: 1.16rem;
  font-weight: 600;
}

.question-row {
  height: 2.5rem;
}

.one-listed-q {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  gap: 0;
}

.question-text-column {
  p {
    display: block;
    width: 15.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    margin-bottom: 0;
  }
}

.controls-column {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-shrink: 1;

  .edit-icon-primary-colour {
    margin-left: 0 !important;
  }
}

.edit-icon-primary-colour {
  color: global.$primary-color !important;
  margin-left: 15px;
  float: right;
}