@use "../../../scss-fragments/global";
@use "../../../scss-fragments/forms";


.toggle-check-content {
    display: flex;
        flex-grow: 1;
        flex-direction: column;
}

.toggle-check-holder {
    @include forms.form-field-holder;

    cursor: pointer;

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed !important;
    }

    .toggle-check-outer {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 3rem;
    }

    .toggle-check {
        @include global.standard-rollover-transition;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50em;
        border-width: 2px;
        border-style: solid;

        .icon {
            height: 1rem;
        }
    }

    .checked {
        border-color: global.$success-color;
        background-color: global.$success-color;
        color: global.$success-dark-color;
    }

    .unchecked-x {
        border-color: global.$error-color;
        background-color: global.$error-color;
        color: global.$error-bg-color;
    }

    .unchecked-add {
        border-color: global.$primary-color;
        background-color: global.$off-white;
        color: global.$primary-color;
    }
}
