@use "../../scss-fragments/global";

.bugreports-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.bugreports-search {
    .search-box {
        min-width: 22rem;
        max-width: 22rem;
    }
}

.change-user-panel-content {
    .submit-btn {
        margin-top: 1rem !important;
        margin-bottom: 0 !important;
    }
}

.bugreports-panel {
    @include global.panel-style;
    @include global.panel-shadow;

    position: relative;

    width: 100%;
    /* max-height: calc(100% - 6rem); */
    flex-grow: 1;

    color: global.$label-color;
    font-weight: 500;

    /* margin-right:0.5rem; */
    margin: 0;

    button {
        margin: 0 !important;
        padding: 0.25rem 1rem !important;
        height: 2.75rem;
    }

    .add-btn {
        position: absolute;
        left: 2.4rem;
        top: 1.5rem;
        z-index: 1000;
    
        display: flex;
        align-items: center;
        justify-content: center;
    
        width: 2rem;
        height: 2rem;
        border-radius:100em;
        border-width: 2px;
        border-style: solid;
        border-color: global.$twitch-purple;
        color: global.$twitch-purple;
    
        cursor: pointer;
    }

    .scroll-container {
        width: 100%;
        height: calc(100vh - 23rem);
        max-height: calc(100vh - 23rem);
        overflow-y: scroll;

        &::-webkit-scrollbar-track {
            margin-top: 3rem;
        }
    }

    & a {
        color: global.$primary-color;

        &:visited {
            color: global.$primary-color;
        }

        &:hover {
            color: global.$primary-color;
        }

        &:active {
            color: global.$primary-color;
        }
    }

    .grid {
        display: grid;

        grid-template-columns: auto auto auto 30% auto auto auto auto auto auto;
        // gap: 0.5rem;
        padding: 0.5rem;

        align-items: center;

        .header {
            @include global.unselectable;

            position: sticky;
            top: 0;
            background-color: global.$panel-body-bg;
            padding-bottom: 1rem;
            width: 100%;
            z-index: 999;
            margin-left:0.5rem;
            white-space: nowrap;
        }

        .pp-style {
            border-color: global.$panel-body-bg;
        }

        .icons {
            color: global.$primary-color;
            margin: 0.2rem 0.75rem;
            cursor: pointer;
        }

        hr {
            position: absolute;
            left: 1.5rem;
            top: 3rem;
            width: calc(100% - 4.5rem);
            z-index: 1000;
        }

        .full-width {
            grid-column-start: 1;
            grid-column-end: 9;
        }

        .centered {
            justify-self: center;
            text-align: center;
        }
    }

    .grid-item {
        padding: 0.5rem 1rem 0.5rem 0.5rem;
        height: 100%;
    }
    .grid-row-spacer {
        height: 0.5rem;
        background-color: #fff;
    }
    .grid-item.row-new-status-colour {
        background-color: global.$warning-light-color;
    }
    .grid-item.row-inprogress-status-colour {
        background-color: global.$warning-color;
        color: #000;
    }
    .grid-item.row-onhold-status-colour {
        background-color: global.$light-grey-1;
        color: global.$mid-grey-1;
    }
    .grid-item.row-ignored-status-colour {
        background-color: global.$error-light-color;
        color:global.$error-dark-color;
    }
    .grid-item.row-complete-status-colour {
        background-color: global.$success-bg-color;
        color: global.$success-dark-color;
    }

    .grid-item-long {
        /* white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; */
    }



    .new-status-colour {
        .drop-down-list {
            background-color: global.$warning-color;
        }
        .top-row {
            color: global.$error-dark-color;
            background-color: global.$warning-color;
        }
    }
    .inprogress-status-colour {
        .drop-down-list {
            color: global.$warning-dark-color;
            background-color: global.$warning-light-color;
        }
        .top-row {
            color: global.$warning-dark-color;
            background-color: global.$warning-light-color;
        }
    }
    .onhold-status-colour {
        .drop-down-list {
            color: global.$mid-grey-1;
            background-color: global.$light-grey-1;
        }
        .top-row {
            color: global.$mid-grey-1;
            background-color: global.$light-grey-1;
        }
    }
    .ignored-status-colour {
        .drop-down-list {
            background-color: global.$error-light-color;
            color:global.$error-dark-color;
        }
        .top-row {
            background-color: global.$error-light-color;
            color:global.$error-dark-color;
        }
    }
    .complete-status-colour {
        .drop-down-list {
            background-color: global.$success-bg-color;
            color: global.$success-dark-color;
        }
        .top-row {
            background-color: global.$success-bg-color;
            color: global.$success-dark-color;
        }
    }
    


    .high-priority-colour {
        .drop-down-list {
            color: #fff;
            background-color: global.$error-bg-color;
        }
        .top-row {
            color: #fff;
            background-color: global.$error-bg-color;
        }
    }
    .medium-priority-colour {
        .drop-down-list {
            color: #fff;
            background-color: global.$warning-color!important;
        }
        .top-row {
            color: #fff;
            background-color: global.$warning-color!important;
        }
    }
    .low-priority-colour {
        .drop-down-list {
            color: #fff;
            background-color: global.$success-color;
        }
        .top-row {
            color: #fff;
            background-color: global.$success-color;
        }
    }
    .wishlist-priority-colour {
        .drop-down-list {
            color: global.$primary-color;
            background-color: global.$secondary-bg-color;
        }
        .top-row {
            color: global.$primary-color;
            background-color: global.$secondary-bg-color;
        }
    }
    
}
