@use "../../../scss-fragments/global";
@use "../../../scss-fragments/forms";

.rb-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0;

    label {
        padding-left: 1rem;
        margin-right: 1.5rem;
    }
}

.rb-content {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.expanded-content {
    @include global.panel-style;
    @include global.standard-rollover-transition;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0;
    border-width: 0;
    background-color: global.$light-grey-1;
    overflow-y: hidden;
    max-height: 0px;
    height: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    opacity: 0;
}

.expanded {
    max-height: fit-content;
    height: fit-content;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-top: 1rem;
    opacity: 1;
}
