@use '../../../scss-fragments/global';

.rate-stream-popup-content {
    min-width: 20rem;
    min-height: 14rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    h1 {
        text-align: center;
    }

    .rating-stars-container {
        display: flex;
        flex-direction: row;
        gap: 0;
        justify-content: center;
        margin: 0;
        padding: 0;
        cursor: pointer;
    }

    .has-rated {
        pointer-events: none;
        cursor: default;
    }

    .rating-star {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0.5rem;
    }

    .active-star {
        svg {
            color: global.$primary-color;
        }
    }
    .inactive-star {
        svg {
            color: global.$dark-grey-3;
        }
    }
}
