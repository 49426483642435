@use "../../scss-fragments/global";

.tabbed-invite-panel-holder {
    position: relative;
    width: 90%;
    max-width: 500px;
    margin: auto;

    max-height: 100vh;

    .scroll-container {
        margin: 2rem 0;
        max-height: calc(60vh - 18rem);
        overflow-y: auto;
        padding-right: 1rem;
    }

    h2 {
        margin-bottom: 2rem;
    }

    .close-btn {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 3rem;
        height: 3rem;
        border-radius:100em;
        border-width: 2px;
        border-style: solid;
        border-color: global.$twitch-purple;
        color: global.$twitch-purple;

        cursor: pointer;

        .icon {
            height: 1.2rem;
        }
    }
}

.tabbed-invite-user-content {
    display: flex;
    flex-direction: column;

    min-height: 60vh;
    max-height: 60vh;
    margin-top: 1rem;

    & p {
        margin-bottom: 2rem;
    }

    .submit-btn {
        margin-bottom: 1rem !important;
    }

    .drop-gap {
        margin-bottom: 2rem;
    }
}

.tabbed-invite-form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
