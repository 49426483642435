@use "../../scss-fragments/global";

.customise-holder {
    position: fixed;
    left: 0;
    top: 4rem;
    width: 100vw;
    height: calc(100vh - 4rem);
    max-height: calc(100vh - 4rem);
    background-color: global.$body-bg-color;

    /* padding: 2rem 2rem 0; */

    .main-top-options {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        margin-left: 2rem;
        font-weight: 600;
        color: global.$primary-color;
        cursor: pointer;
    }

    .tab-content {
        max-height: calc(100vh - 28rem);
        min-height: calc(100vh - 28rem);
        overflow-y: scroll;
        margin-right: -1rem;
        padding-right: 1rem;
    }

    .audio-panel {
        width: 50%;
    }

    .audio-control-header {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        flex-grow: 1;
        align-items: baseline;
    
        color: #FFF;
    
        h4 {
            color: #FFF !important;
        }
    }

    .audio-heading-note {
        font-size: 1rem;
        font-weight: 400;
        color: global.$light-grey-1;
    }

    .play-button {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 4rem;
        height: 4rem;

        border: 2px solid global.$primary-color;
        color: global.$primary-color;
        border-radius: 50em;
        background-color: #FFF;

        cursor: pointer;
    }

    
}

.lightbox {
    img {
        max-width: 90%;
        max-height: 90%;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .close-btn {
        position: fixed;
        right: 1rem;
        top: 5rem;
    }

    .fl-row {
        position: fixed;
        left: 50%;
        bottom: 10%;
        transform: translate(-50%, 0);

        color: #FFF;
        font-weight: 600;
    }
}

.prog-panel {
    max-width: 50rem;
    width: fit-content;
    min-width: 40rem;
}

.prog {
    display: flex;
    
    min-height: 24rem;
    width: 100%;
    padding: 1rem 2rem;

    font-weight: 500;
    text-align: center;
    align-items: center;
    justify-content: center;

    .fl-column {
        flex-grow: 1;
    }
}
