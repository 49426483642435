@use '../../scss-fragments/global';

.circle-perc {
    position: relative;
    width: 5rem;
    height: 5rem;
    margin: 0;
    padding: 0.1rem;

    svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        stroke-linecap: round;
    }

    .back-circle {
        stroke: global.$light-text-color;
        stroke-width: 1.5px;
    }

    .perc-circle {
        stroke: global.$primary-color;
        stroke-width: 2px;
    }
}
