@use "../../../scss-fragments/global";

.feature-panel {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0;
    overflow-y: hidden;
    max-height: fit-content;
    height: fit-content;

    /* padding-top: 1.5rem;
    padding-bottom: 1.5rem; */
    font-weight: 400;

    .fl-row {
        width: 100%;
    }

    h5 {
        margin: 0;
    }

    h4 {
        margin: 0;
        color: #6E7191;
    }

    hr {
        margin: 0;
    }

    .scroll-container {
        width: 100%;
        max-height: 20rem;
        overflow-y: scroll;

        &::-webkit-scrollbar-track {
            margin-top: 3rem;
        }
    }

    .add-margin {
        width: calc(100% - 6px);
        margin: 3px;
        border: 0px solid #FFF;
        border-radius: 0.5rem;
    }

    .submit-btn {
        margin: 1rem 0 !important;
    }

    .add-btn { 
        display: flex;
        align-items: center;
        justify-content: center;
    
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 0.75rem;
        border-radius:100em;
        border-width: 2px;
        border-style: solid;
        border-color: global.$twitch-purple;
        color: global.$twitch-purple;
    
        cursor: pointer;
    }

    .edit-btns {
        padding-right: 1rem;
    }

    .standard-button {
        margin: 0 !important;
    }

    .grid {
        display: grid;

        grid-template-columns: auto auto auto auto auto auto;
        gap: 1rem;

        align-items: center;

        max-width: 100%;

        .header {
            @include global.unselectable;

            position: sticky;
            top: 0;
            padding-bottom: 0.5rem;
            margin-bottom: -0.5rem;

            width: 100%;
            z-index: 999;

            align-self: flex-start;
            white-space: nowrap;

            background-color: global.$light-grey-1;
        }

        .cell {
            height: fit-content;
            align-self: flex-start;
            /* margin-bottom: 0.5rem; */
            overflow: hidden;
        }

        .ellip {
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
        }

        .tag-list {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        .restricted {
            color: global.$error-color;
        }

        &.less-detail {
            grid-template-columns: auto auto auto;

            hr {
                grid-column-start: 1;
                grid-column-end: 4;
            }
        }
    }

    .chained-feature-title {
        font-weight: 400;
    }

    .inner-panel {
        border-radius: 0px;
        border-width: 0px;
        border-style: solid;
        background-color: #FFF;
        padding: 0;
        width: 100%;

        .header {
            background-color: #FFF !important;
        }

        hr {
            margin: 0 0.5rem 0 0;
            display: grid;
            grid-column-start: 1;
            grid-column-end: 7;
        }
    }

    .edit-order-button {
        width: 2rem;
        height: 2rem;
        max-width: 2rem;
        max-height: 2rem;
        min-width: 2rem;
        min-height: 2rem;
        background-color: #FFF;
        border: 2px solid global.$primary-color;
        border-radius: 50%;
        color: global.$primary-color;
    
        display: flex;
        align-self: flex-start;
        align-items: center;
        justify-content: center;
        justify-items: center;
        padding-left: 1px;
        padding-bottom: 1px;
        margin-top: 1.5rem;
        
        cursor: pointer;
    }
}
