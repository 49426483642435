@use "../../scss-fragments/global";
@use "../../scss-fragments/forms";


.confirmation-code-input-label {
    display: block;
    text-align: center;
}

.confirmation-code-input-content {
    display: flex;
    align-items: center;
    justify-content: center;
}