@use "../../scss-fragments/global";

.stream-time {
    text-transform: lowercase;
    font-weight: 400;
    color: global.$mid-grey-2;
}

.stream-time-dark {
    text-transform: lowercase;
    font-weight: 400;
    color: global.$mid-grey-1;
}

.stream-twitch-logo {
    svg {
        height: 2rem;
    }
    path {
        fill: currentColor;
        stroke: currentColor;
    }
}
.channel{
    width:100%;
}
.stream-container {
    width:100%;
    border: 1px;
    padding:10px;
}
.stream-twitch-logo-list {
    color: global.$primary-color;
    svg {
        height: 2rem;
    }
    path {
        fill: currentColor;
        stroke: currentColor;
    }
}
.add-btn { 
    display: flex;
    align-items: center;
    justify-content: center;

    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.75rem;
    border-radius:100em;
    border-width: 2px;
    border-style: solid;
    border-color: global.$twitch-purple;
    color: global.$twitch-purple;

    cursor: pointer;
}

.stream-live-icon {
    color: global.$off-white;
}

.stream-details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-weight: 500;
    color: global.$label-color;

    .time {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .local-time {
        font-weight: 400;
    }

    a {
        color: global.$label-color;
        text-decoration: none;
    }

    .submit-btn {
        margin: 0 !important;
        min-width: 15rem;
        max-width: 15rem;
        white-space: nowrap;
    }
}

.stream-list-view-data-block {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    gap: 0.5rem !important;

    font-size: 1rem;
    font-weight: 400;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    svg {
        color: global.$light-text-color;
    }

    color: global.$primary-color;
}

.sl-local-time {
    font-weight: 400;
}
