@use 'fonts';

/* Colours */
$twitch-purple: #9146FF;

/* Colour names according to the brand guide */
$primary-color: $twitch-purple;
$primary-light-color: #E4DAFF;
$primary-mid-color: #BCA4FF;
$secondary-color: #1CC8EE;
$secondary-bg-color: #DEF9FF;
$error-light-color: #FFDFED;
$error-bg-color: #F275AA;
$error-color: #DC3545;
$error-dark-color: #C30052;
$success-color: #5DE8A0;
$success-dark-color: #00966D;
$success-bg-color: #9CF5C7;
$success-light-color: #DFFFF6;
$warning-color: #F6C562;
$warning-dark-color: #ff820c;
$warning-light-color: #FFF9EF;

/* Aliases in case we need to change these to something other than the above */
$main-button-color: $twitch-purple;
$active-color: $success-color;
$scheduled-color:  $warning-color;

/* Greys */
$dark-grey-1: #14142B;
$dark-grey-2: #4E4B66;
$dark-grey-3: #6E7191;
$mid-grey-1: #A0A3BD;
$mid-grey-2: #D9DBE9;
$light-grey-1: #EFF0F6;
$light-grey-2: #F7F7FC;
$light-grey-3: #FCFCFC;

/* Grey aliases, that make more sense to use */
$title-color: $dark-grey-2;
$link-color: $dark-grey-1;
$body-copy-color: $dark-grey-2;
$label-color: $dark-grey-3;
$light-text-color: $mid-grey-1;
$dark-text-color: $dark-grey-1;
$placeholder-color: $mid-grey-1;
$line-color: $mid-grey-2;
$input-bg-color: $light-grey-2;
$body-bg-color: #F3EFFF;
$off-white: $light-grey-3;
$top-bar-color: #FFF;
$panel-body-bg: #FFF;


/* Gradient colours */
$gradient-primary-dark: $primary-color;
$gradient-primary-light: $error-bg-color;
$gradient-secondary-dark: $primary-color;
$gradient-secondary-light: $success-color;
$gradient-accent-dark: $primary-color;
$gradient-accent-light: $warning-color;

/* Gradients */
$gradient-primary: linear-gradient(to bottom right, $gradient-primary-dark, $gradient-primary-light);
$gradient-secondary: linear-gradient(to bottom right, $gradient-secondary-light, $gradient-secondary-dark);
$gradient-accent: linear-gradient(to bottom right, $gradient-accent-dark, $gradient-accent-light);

/* Some vars for panel corners so we can style our own panels without the mixin if needed, while keeping things consistent */
$panel-border-radius: 1rem;
$tab-border-radius: 2rem;
$button-border-radius: 0.8rem;

/* Some global element vars */
$content-edge-buffer: 3rem;
$panel-shadow: drop-shadow(0px 8px 16px rgba(17, 17, 17, 0.1));

/* Account type colours */
$koko-ac-color: $warning-color;
$koko-ac-bg-color: $warning-light-color;
$super-user-ac-color: $warning-color;
$super-user-ac-bg-color: $warning-light-color;
$agency-ac-color: $secondary-color;
$agency-ac-bg-color: $secondary-bg-color;
$client-ac-color: $success-dark-color;
$client-ac-bg-color: $success-bg-color;
$streamer-ac-color: $primary-color;
$streamer-ac-bg-color: $primary-light-color;

/* Transcript pill colours */
$default-tr-color: $warning-dark-color;
$default-tr-bg-color: $warning-light-color;
$disrupter-tr-color: #82E9FF;
$disrupter-tr-bg-color: #82E9FF;
$feature-tr-color: $success-dark-color;
$feature-tr-bg-color: $success-light-color;
$bot-tr-color: #BCA4FF;
$bot-tr-bg-color: #BCA4FF;

/* Account type colours as an easy to use css definition */
.koko-ac-color {
    color: white;
    border-color: #000000;
    background-image: linear-gradient(to bottom right, #000000, #909090);
   /* background-color: $koko-ac-bg-color;*/
}

.koko-pp-border {
    outline-color:#909090;
}

.super-user-ac-color {
    color: white;
    border-color: $super-user-ac-color;
    background-image: linear-gradient(to bottom right, #FA26CD, #FAEE25);
    /*background-color: $super-user-ac-bg-color;*/
}

.super-user-pp-border {
    outline-color: #FA26CD;
}

.agency-ac-color {
    color: white;
    border-color: $agency-ac-color;
    background-image: linear-gradient(to bottom right, #FF6c06, #FDB211);
   /* background-color: $agency-ac-bg-color;*/
}

.agency-pp-border {
    outline-color: #FF6c06;
}

.producer-ac-color {
    color: white;
    border-color: $agency-ac-color;
    fill: #ffffff; 
    background-image: linear-gradient(to bottom right, #1F6CFF, #04F0FB);
   /* background-color: $agency-ac-bg-color;*/
}

.producer-pp-border {
    outline-color:   #1F6CFF;
}

.client-ac-color {
    color: white;
    border-color: $agency-ac-color;

    background-image: linear-gradient(to bottom right, #FF6A07, #F093F8);
}

.client-pp-border {
    outline-color:  #F093F8;
}

.streamer-ac-color {
    color: white;
    border-color: $agency-ac-color;
    background-image: linear-gradient(to bottom right, #8A46FF, #FBB4FF);
}

.streamer-pp-border {
    outline-color:  #8A46FF;
}

.not-set-ac-color {
    color: white;
    border-color: $agency-ac-color;
    background-image: linear-gradient(to bottom right, #A91B27, #F2272E);
}

.not-set-pp-border {
    outline-color:  #F2272E;
}

.alert {
    color: $error-dark-color;
}

.alert-bg {
    background-color: $error-light-color;
    border: solid 2px $error-bg-color;
}

.error-color {
    color: $error-dark-color;
}

.success-color {
    color: $success-dark-color;
}

/* Mixin styles */
@mixin panel-style {
    border-radius: $panel-border-radius;
    border-width: 2px;
    border-style: solid;
    margin: 1rem 0;
    padding: 1.5rem;
    border-color: $panel-body-bg;
    background-color: $panel-body-bg;
}

@mixin panel-shadow {
    /* $panel-shadow: drop-shadow(0px 8px 16px rgba(17, 17, 17, 0.1)); */
    filter: $panel-shadow;
    /* box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.1); */
}

@mixin tab-style {
    border-radius: $tab-border-radius $tab-border-radius 0 0;
    border-width: 2px;
    border-style: solid;
    margin: 0;
    padding: 1.5rem 1rem 2.5rem;
    display: flex;
    font-weight: bold;
    font-size: 1.1rem;
    height: 3rem;
    justify-content: center;
    flex: 1;
    border-color: $panel-body-bg;
    background-color: $panel-body-bg;
}

@mixin pill-style {
    border-radius: 100em;
    border-width: 0px;
    border-style: solid;
    height: 2rem;
    display: flex;
    align-items: center;
    margin: 1rem 0;
    padding: 0 1rem;
}

@mixin button-style {
    border-radius: $button-border-radius;
    border-width: 2px;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 1rem 0;
    padding: 1rem;
    background-color: #FFF;
    border-color: $primary-color;
}

@mixin round-button-style {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 3rem;
    height: 3rem;
    border-radius:100em;
    border-width: 2px;
    border-style: solid;
    border-color: $twitch-purple;
    color: $twitch-purple;

    cursor: pointer;
}


@mixin unselectable {
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

@mixin standard-rollover-transition {
    transition: all 0.3s;
}

/* Utility classes */
.button-active {
    cursor: pointer;
}

.button-inactive {
    cursor: not-allowed !important;
    background-color: $mid-grey-1 !important;
    border-color: $mid-grey-1 !important;
}

.red-button {
    background-color: $error-dark-color !important;
    border-color: $error-dark-color !important;
}

.yellow-button {
    background-color: $warning-dark-color !important;
    border-color: $warning-dark-color !important;
}

.fill-height {
    height: 100%;
}

.fill-vh {
    height: 100vh;
}

.fill-width {
    width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.inline {
    display: inline-block;
}

.rel {
    position: relative;
}

.abs-bottom {
    position: absolute;
    bottom: 0;
}

.shift-up-h {
    margin-top: -0.5rem;
}
.shift-up-1 {
    margin-top: -1rem;
}
.shift-up-1-5 {
    margin-top: -1.5rem;
}
.shift-up-2 {
    margin-top: -2rem;
}

.fl-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}
.fl-row1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .2rem;
}
.fl-column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.fl-wrap {
    flex-wrap: wrap;
}

.compact {
    gap: 0.5rem !important;
}

.no-gap {
    gap: 0 !important;
}

.shrink {
    flex-shrink: 1;
}

.grow {
    flex-grow: 1;
}
.grow-2 {
    flex-grow: 2;
}
.grow-100 {
    flex-grow: 100;
}

.fl-equal {
    flex: 1;
}

.fl-50-perc {
    min-width: 50%;
}

.j-center {
    justify-content: center !important;
    justify-items: center !important;
    text-align: center;
}

.j-right {
    justify-content: right !important;
    justify-items: right !important;
    text-align: right;
}

.align-bottom {
    align-items: flex-end !important;
    align-self: flex-end !important;
}

.fl-start {
    align-self: flex-start !important;
}

.fl-end {
    align-self: flex-end !important;
}

.fl-bottom {
    align-self: baseline !important;
}

.a-btn {
    text-decoration: none;

    &:active {
        text-decoration: none;
    }

    &:hover {
        text-decoration: none;
    }

    &:visited {
        text-decoration: none;
    }
}

.tight {
    margin: 0 !important;
}

.full-height {
    height: 100%;
}

.fit-content {
    max-width: fit-content !important;
}

.small-b-margin {
    margin-bottom: 0.5rem;
}

.no-bottom-margin {
    margin-bottom: 0 !important;
}

.dark-text {
    color: $dark-text-color !important;
}

.light-text {
    color: $light-text-color !important;
}

.bold-text {
    font-weight: 600 !important;
}

.warning-text {
    color: $error-dark-color !important;
}

.confirm-text {
    color: $success-dark-color !important;
}

.attention-text {
    color: $warning-dark-color !important;
}

.standard-text-color {
    color: $body-copy-color;
}

.white-text {
    color: $off-white;
}

.small-text {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
}

.no-wrap {
    white-space: nowrap;
}

.indented {
    margin-left: 1rem;
}

.label {
    margin-bottom: 0.25rem;
    font-weight: 600;
    color: $dark-grey-3;
}

.capitalise {
    text-transform: capitalize;
}

.all-lower {
    text-transform: lowercase;
}

.edit-icon {
    color: $primary-color;
    cursor: pointer;
}
.edit-icon.disabled {
    color: $mid-grey-1;
    cursor: not-allowed;
}

.not-allowed {
    border-color: $mid-grey-1 !important;
    color: $mid-grey-1 !important;
    cursor: not-allowed !important;
}

.edit-icon-wh {
    color: $off-white;
    cursor: pointer;
}

.edit-icon-gr {
    color: $mid-grey-1;
    cursor: pointer;
}

.edit-icon-light {
    color: $primary-mid-color;
    cursor: pointer;
}

.info-icon {
    color: $primary-color;
}

.primary-color {
    color: $primary-color;

    a {
        color: $primary-color;
        &:active {
            color: $primary-color;
        }
        &:hover {
            color: $primary-color;
        }
        &:visited {
            color: $primary-color;
        }
    }

    svg {
        fill: $primary-color;
    }
}

/* General text styles */
h1 {
    /* font-size: 4rem; */
    font-weight: 700;
    color: $title-color;
}

h2 {
    /* font-size: 3rem; */
    font-weight: 700;
    color: $title-color;
}

h3 {
    /* font-size: 2rem; */
    font-weight: 700;
    color: $title-color;
}

h4 {
    font-size: 1.4rem;
    font-weight: 600;
    color: $title-color;
}

h5 {
    font-size: 1.2rem;
    font-weight: 600;
    color: $title-color;
}

/* P style is just dropped into body in index.scss, so no need for this one here as it might be confusing */
/* p {
    font-size: 1rem;
    font-style: 400;
    color: $body-copy-color
} */

a {
    @include standard-rollover-transition;
    color: $link-color;
    text-decoration: underline;

    &:active {
        color: $link-color;
    }
    &:hover {
        color: $link-color;
    }
    &:visited {
        color: $link-color;
    }
}

/* Standard button! */
.standard-button {
    @include button-style;
    margin: 1rem 0 2rem;
    width: 100%;
    height: 3.5rem;
    background-color: $primary-color;
    border-color: $primary-color;
    color: #fff;
    overflow: hidden;
}
.standard-button.not-allowed {
    background-color: $mid-grey-1 !important;
    border-color: $mid-grey-1 !important;
    color: #FFF !important;
    cursor: not-allowed !important;
}

.heading-slim-button {
    padding: 0.5rem 1rem;
    height: 2.5rem;
    margin-bottom: -4px !important;
}

/* Bootstrap over-rides - mostly not even used */
.container {
    width: 100vw;
    margin-left: 0;
    margin-right: 0;
}
.row {
    width: 100vw;
}
/* Set the overall scale of the site, works cos we are using rem for sizing of stuff */
:root {
    font-size: 12px;
}

/* Global Page Elements */

/* Content page wrappers - you'll need to wrap your main content in this stuff so it all looks the same */
.content-page-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.sticky-header {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    z-index: 50;
}

.content-page-top {
    @include panel-style;
    @include panel-shadow;
    @include standard-rollover-transition;

    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    width: calc(100% - 6rem);
    margin: 0 3rem 2rem 3rem;
    padding: 2rem 1.5rem;
    // background-color: $body-bg-color;

    margin-top: -1rem !important;
    /* position: sticky; */
    top: 0rem;
    z-index: 99;
    
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;

    /* overflow: hidden; */
}

.content-page-content-container {
    flex-grow: 1;
    width: 100%;
    margin: 0;
    /* overflow-x: hidden; */
    /* overflow-y: auto; */
}

.content-page-main-content {
    height: 100%;
    padding: 1rem $content-edge-buffer 1rem $content-edge-buffer;
}

.blackout {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);

    z-index: 10001;
}

.ac-pill {
    @include pill-style;

    position: relative;

    font-weight: 800;
    justify-content: center;
    align-content: center;

    min-width: 15rem;
    max-width: 15rem;
    width: 15rem;

    & p {
        margin: 0 0.5rem;
    }

    .icon {
        position: absolute;
        left: 0.5rem;
        top: 50%;
        transform: translate(0, -50%);
    }

    .tw-icon {
        position: absolute;
        left: 0.5rem;
        top: 50%;
        height: 1rem;
        fill:#FFFFFF;
        transform: translate(0, -50%);
    }
}

.qu-pill {
    margin: 0;
}

.filters-container {
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    gap: 1rem;
    align-items: center;
    margin-top: 1rem;
    /* margin-bottom: -1rem; */

    .filter {
        width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.round-button {
    @include round-button-style;       
}

.small-add-button {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
}

.underlined-panel-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $mid-grey-2;
}

.tw-tooltip {
    @include panel-style;
    /* @include panel-shadow; */
    @include standard-rollover-transition;

    cursor: default;
    pointer-events: none;
    z-index: 1000000;

    border-radius: $button-border-radius;

    position: fixed;
    transform: translate(-50%, 0);

    color: $primary-color;
    background-color: #FFF;
    border-color: $primary-mid-color;

    margin: 0;
    padding: 0.5rem;
    /* white-space: nowrap; */

    max-width: 24rem;
    min-width: 12rem;
}

.tight-hr {
    margin: 0.5rem 0 1rem;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: $mid-grey-2;
    margin: 0.5rem;
}

::-webkit-scrollbar-thumb {
    background: $primary-color;
    border-radius: 4px;
}
