@use "../../scss-fragments/global";

.campaigns-overview-container {
    display: grid;

    grid-template-columns: calc(50% - 1rem) calc(50% - 1rem);
    gap: 2rem;

    /* padding-bottom: 3rem; */

    .editCampaignname {
        width:40%;
    }
    .heading {
        flex-direction: row !important;
        align-items: center;
        grid-column-start: 1;
        grid-column-end: span 2;
        margin-top: -1rem !important;
        position: sticky;
        top: 0rem;
        z-index: 99;

        border-top-right-radius: 0 !important;
        border-top-left-radius: 0 !important;

        .submit-btn {
            margin-bottom: 0rem !important;
            margin-top: 0rem !important;
            height: 3rem !important;
        }

        h2 {
            margin: 0;
        }
    }

    .edit-mode-heading {
        border-color: global.$success-dark-color !important;
        border-top-color: global.$off-white !important;

        .round-button {
            background-color: global.$off-white;
        }
    }

    .campaign-overview-panel {
        @include global.panel-style;
        @include global.panel-shadow;
        @include global.standard-rollover-transition;
        margin: 0;

        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        overflow: hidden;
    }

    .save-button {
        @include global.button-style;
        height: 2rem;
        color: global.$off-white;
        background-color: global.$success-dark-color;
        border-color: global.$success-dark-color;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-width: 0;
        margin: 0;
        cursor: pointer;
    }

    .radio-group {
        margin: 1rem 0 !important;
    }

    .drop-down {
        margin: 0 0 1rem !important;
    }

    .uneditable {
        margin-top: -0.5rem;
    }

    .wide {
        grid-column-start: 1;
        grid-column-end: span 2;
    }

    .tall {
        height: 46rem;
        min-height: 46rem;
        max-height: 46rem;
    }

    .tall-edit {
        height: 52rem;
        min-height: 52rem;
        max-height: 52rem;
    }

    .standard {
        height: 35rem;
        min-height: 35rem;
        max-height: 35rem;
    }

    .st-full-scroll-area {
        height: 26rem;
        min-height: 26rem;
        max-height: 26rem;
        padding-bottom: 1rem;
        padding-right: 1rem;
        overflow-y: scroll;
    }

    .small-add-button {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
    }

    .collapsed {
        height: 4.5rem;
        min-height: 4.5rem;
        max-height: 4.5rem;
        /* border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; */
    }

    hr {
        margin: 0.5rem 0;
    }

    .headed-panel {
        margin-bottom: 1.5rem;
    }

    .alert {
        padding: 0rem 1rem;
        margin-bottom: 0;
    }

    .stacking-button {
        padding: 0.5rem 1rem;
        height: 2.5rem;
        margin-bottom: -4px !important;
    }
}

.co-popup-content {
    margin-top: 1rem;

    & p {
        margin-bottom: 2rem;
    }

    .submit-btn {
        margin-bottom: 1rem !important;
    }

    .drop-gap {
        margin-bottom: 2rem;
    }
}

.confirm-remove-stream-pop {
    max-width:550px;
}

.edit-mode-bg {
    background-image: url(../../assets/edit-stripes.svg);
    background-repeat: repeat;
    background-size: 84px;
}

.edit-sfn-field {
    width: 10rem;
    max-width: 10rem;
    min-width: 10rem;
}

.stream-bot-add-copy {
    display: flex;
    flex-direction: row;
    align-items: end;
    gap: 1rem;
}

.add-bot-copy-btn {
    @include global.button-style;
    margin: 0 0 1.7rem;
    width: fit-content;
    height: 3.5rem;
    background-color: global.$primary-color;
    border-color: global.$primary-color;
    color: #fff;
}

.added-bot-copy {
    p {
        margin: 0.5rem 0 !important;
    }

    margin: 0 0 2rem;
    padding-right: 0.5rem;

    max-height: 12.5rem;
    overflow-y: auto;
}

.remove-bot-copy {
    color: global.$error-dark-color;
    cursor: pointer;
}

.co-tabbed-panel {
    height: calc(100vh - 19rem);
    min-height: calc(100vh - 19rem);
    max-height: calc(100vh - 19rem);
}

.co-tab-content {
    display: flex;
    flex-direction: row;
    gap: 4rem;
    width: 100%;
}

.co-tab-column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
}

.co-fullcol-scroll-area {
    height: calc(100vh - 27.25rem);
    min-height: calc(100vh - 27.25rem);
    max-height: calc(100vh - 27.25rem);
    /* padding-bottom: 1rem; */
    padding-right: 1rem;
    overflow-y: scroll;
}

.co-halfcol-scroll-area {
    height: calc(50vh - 16.5rem);
    min-height: calc(50vh - 16.5rem);
    max-height: calc(50vh - 16.5rem);
    /* padding-bottom: 1rem; */
    padding-right: 1rem;
    overflow-y: scroll;
    margin-bottom: 0.5rem;
}

.co-tab-content-area {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow: hidden;
}

.list-item-grey-panel {
    @include global.panel-style;
    @include global.standard-rollover-transition;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    margin: 0;
    border-width: 2px;
    background-color: global.$light-grey-1;
    border-color: global.$light-grey-1;
    overflow-y: hidden;
    max-height: fit-content;
    height: fit-content;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-weight: 400;

    .content-column {
        display: flex;
        flex-direction: column;
        gap: 0;
    }

    .inner-panel {
        border-radius: global.$button-border-radius;
        border-width: 0px;
        border-style: solid;
        background-color: #FFF;
        padding: 1rem;
        width: 100%;

        .header {
            background-color: #FFF !important;
        }
    }
}

.analytics-summary-panel {
    @include global.panel-style;
    /* @include global.panel-shadow; */
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    margin: 0;
    border-width: 2px;
    border-color: global.$light-grey-1;
    background-color: #FFF;
    overflow: hidden;
    max-height: fit-content;
    height: fit-content;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-weight: 500;
    color: #6E7191;

    .content-column {
        display: flex;
        flex-direction: column;
        gap: 0;
    }

    .time {
        font-weight: 500;
        color: global.$label-color;
    }

    .summary-right-holder {
        width: 50%;
        max-width: 50%;
        min-width: 50rem;
    }

    .small-analytics {
        @include global.panel-style;
        padding: 0.5rem 1rem;
        margin: 0;

        p {
            margin: 0;
        }
        h4 {
            margin: 0;
        }
    }
    
    .sm-an-features {
        border-color: #F6C562;
        color: global.$light-text-color;
        svg {
            color: #F6C562;
        }
    }

    .sm-an-disruptors {
        border-color: #1CC8EE;
        color: global.$light-text-color;
        svg {
            color: #1CC8EE;
        }
    }

    .sm-an-bots {
        border-color: #C30052;
        color: global.$light-text-color;
        svg {
            color: #C30052;
        }
    }
}

.small-analytics-stat-panel {
    width: 20rem;
    min-width: 20rem;
    max-width: 20rem;
}

.session-analytics-popup {
    width: calc(100vw - 2rem);
    max-width: calc(100vw - 2rem);
    max-height: calc(100vh - 2rem);
    font-weight: 500;
}

.session-analytics-scroll-panel {
    max-height: calc(100vh - 9rem);
    padding: 1rem;
    overflow-y: scroll;
}

.triggered-feature-panel {
    margin-top: 0.5rem;
    margin-left: 5rem;
    background-color: global.$secondary-bg-color;
    color: global.$dark-grey-3;
}

.triggered-feature-holder {
    /* margin-top: calc(-0.5rem - 2px); */
}

.triggered-feature-indent {
    display: flex;
    align-content: center;
    justify-content: right;

    width: 3.5rem;
    min-width: 3.5rem;
    max-width: 3.5rem;
}
