@use "../../scss-fragments/global";

.bot-time {
    /* text-transform: lowercase; */
    font-weight: 400;
    color: global.$mid-grey-2;
}

.bot-time-dark {
    /* text-transform: lowercase; */
    font-weight: 400;
    color: global.$mid-grey-1;
}

.bot-command {
    color: global.$primary-color;
}

.bot-copy-heading {
    margin-bottom: 0.5rem !important;
}

.bot-copy p {
    margin-bottom: 0.2rem;
}

.activate-bot-btn {
    @include global.round-button-style;
    @include global.standard-rollover-transition;
    border-color: global.$off-white;
    background-color: global.$primary-color;
    width: 4rem;
    height: 4rem;

    &:hover {
        background-color: global.$primary-color;
    }
}

.sm-activate-bot-btn {
    @include global.round-button-style;
    @include global.standard-rollover-transition;
    border-color: global.$off-white;
    background-color: global.$primary-color;
    
    width: 2.4rem;
    height: 2.4rem;

    &:hover {
        background-color: global.$primary-color;
    }
}

.control-area {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.5rem;
    min-height: 4rem;

    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    .control-status {
        @include global.pill-style;
        margin: 0;
        padding: 0 0.5rem;
        flex-direction: row;
        gap: 0.5rem;

        background-color: global.$off-white;
        border-color: global.$off-white;
        color: global.$label-color;

        min-height: 4rem;
        max-height: 4rem;
        min-width: 16rem;
        max-width: 16rem;

        font-weight: 400;
    }

    .post-count {
        @include global.round-button-style;
        width: 2.6rem;
        height: 2.6rem;
        font-weight: 600;
        cursor: default;
    }

    .no-posts {
        color: global.$error-dark-color;
        border-color: global.$error-dark-color;
        background-color: global.$error-light-color;
    }

    .some-posts {
        color: global.$success-dark-color;
        border-color: global.$success-dark-color;
        background-color: global.$success-light-color;
    }

    .control-btn {
        @include global.round-button-style;
        width: 2.6rem;
        height: 2.6rem;
        font-weight: 600;
        color: global.$primary-color;
        border-color: global.$primary-color;
        background-color: global.$off-white;
    }

    .control-btn-inactive {
        color: global.$mid-grey-1;
        border-color: global.$mid-grey-1;
        cursor: not-allowed;
    }

}
