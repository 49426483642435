@use "../../../scss-fragments/global";

.date-select-holder {
    position: relative;
    flex-grow: 1;

    input {
        cursor: pointer;
    }

    .calendar-holder {
        position: absolute;
        bottom: 4rem;
        left: 50%;
        transform: translate(-50%);
        z-index: 10001
    }

    .close-calendar {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 10000;
    }
}

.react-calendar {
    @include global.panel-shadow;
    border-radius: 0.5rem;
    border-width: 2px;
    border-color: global.$primary-color;
    padding: 3px;
    font-family: PrimaryFont, Arial, Helvetica, sans-serif;
}

.react-calendar__navigation {
    height: 34px;
    margin-bottom: 0.5rem;
}

.react-calendar__tile {
    padding: 6px 6px;
}

.react-calendar__year-view, .react-calendar__decade-view {
    .react-calendar__tile {
        padding: 1rem 0.5rem;
    }
}

.react-calendar__tile--active {
    background: global.$primary-color;
}
.react-calendar__tile--active:enabled:hover {
    background: global.$primary-color;
}
.react-calendar__tile--active:enabled:focus {
    background: global.$primary-color;
}
