@use "../../scss-fragments/global";

.textarea { 
    width: 100%;
    height: 75px;
}

.grid-item {
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    height: 100%;
}
.grid-item.row-new-status-colour {
    background-color: global.$primary-light-color;
}
.grid-item.row-inprogress-status-colour {
    background-color: global.$secondary-color;
}
.grid-item.row-onhold-status-colour {
    background-color: global.$warning-light-color;
}
.grid-item.row-ignored-status-colour {
    background-color: global.$mid-grey-2;
}
.grid-item.row-complete-status-colour {
    background-color: global.$success-bg-color;
}

/* .grid-item-long {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
} */



.new-status-colour {
    .drop-down-list {
        color: global.$primary-color;
        background-color: global.$primary-light-color;
    }
    .top-row {
        color: global.$primary-color;
        background-color: global.$primary-light-color;
    }
}
.inprogress-status-colour {
    .drop-down-list {
        color: #fff;
        background-color: global.$secondary-color;
    }
    .top-row {
        color: #fff;
        background-color: global.$secondary-color;
    }
}
.onhold-status-colour {
    .drop-down-list {
        color: global.$primary-color;
        background-color: global.$warning-light-color;
    }
    .top-row {
        color: global.$primary-color;
        background-color: global.$warning-light-color;
    }
}
.ignored-status-colour {
    .drop-down-list {
        color: global.$primary-color;
        background-color: global.$mid-grey-2;
    }
    .top-row {
        color: global.$primary-color;
        background-color: global.$mid-grey-2;
    }
}
.complete-status-colour {
    .drop-down-list {
        color: global.$primary-color;
        background-color: global.$success-bg-color;
    }
    .top-row {
        color: global.$primary-color;
        background-color: global.$success-bg-color;
    }
}



.high-priority-colour {
    .drop-down-list {
        color: #fff;
        background-color: global.$error-bg-color;
    }
    .top-row {
        color: #fff;
        background-color: global.$error-bg-color;
    }
}
.medium-priority-colour {
    .drop-down-list {
        color: #fff;
        background-color: global.$warning-color!important;
    }
    .top-row {
        color: #fff;
        background-color: global.$warning-color!important;
    }
}
.low-priority-colour {
    .drop-down-list {
        color: #fff;
        background-color: global.$success-color;
    }
    .top-row {
        color: #fff;
        background-color: global.$success-color;
    }
}
.wishlist-priority-colour {
    .drop-down-list {
        color: global.$primary-color;
        background-color: global.$secondary-bg-color;
    }
    .top-row {
        color: global.$primary-color;
        background-color: global.$secondary-bg-color;
    }
}
.edit-user-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;

    & h2 {
        margin-bottom: 1rem;
    }

    .s-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 2rem;
        row-gap: 0;
    }

    .column-cell {
        display: flex;
        flex-direction: column;
    }

    .no-gap {
        gap: 0;
    }

    .small-gap {
        gap: 1rem;
    }

    .s-profile-pic {
        position: relative;
        flex-shrink: 1;

        .icon-overlay {
            position: absolute;
            right: 0.6rem;
            bottom: 0.6rem;
            width: 2rem;
            height: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .pp-style {
        border-color: global.$mid-grey-2;
    }

    .s-edit-icon {
        color: global.$primary-color;
        cursor: pointer;
    }

    .pp-edit {
        background-color: #FFF;
        border: 0px solid global.$primary-color;
        border-radius: 50em;
    }

    .tw-ppic-icon {
        width: 100%;
    }
}
