@use "../../scss-fragments/global";
@use "../../scss-fragments/forms";

.confirmation-code-input-single-holder {
    @include forms.form-field-holder; //some style based on form-field

    width: 60px;
    display: inline-flex;
    flex-direction: row;

    .confirmation-code-input-single-content-holder {
        display: inline-flex;
        flex-direction: column;
    }
    
    .confirmation-code-input-single-content {
        display: inline-flex;
    }
    
    input {
        @include forms.form-field-style; //some style based on form-field
        width: 4rem;
        height: 4rem;
        text-align: center;
        font-weight: bolder;
        font-size: 24px;
    }
    
    input:focus {
        outline-color: global.$primary-color;
        background-color: #FFF;
    }

}
