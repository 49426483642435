@use "../../../scss-fragments/global";
@use "../../../scss-fragments/forms";

.select-holder {
    @include forms.form-field-holder;
}

.select {
    position: relative;
    display: inline-block;
    width: 100%;
}    .select select {
        @include forms.form-field-style;
        display: inline-block;
        width: 100%;
        cursor: pointer;
        outline: 0;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
        .select select::-ms-expand {
            display: none;
        }
        
        .select select:focus {
            border-color: global.$primary-color;
            background: global.$off-white;
        }
        .select select:disabled {
            pointer-events: none;
        }
.select_arrow {
    position: absolute;
    top: 1.4rem;
    right: 1rem;
    width: 0px;
    height: 0px;
    border: solid #7b7b7b;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    pointer-events: none;
}
.select select:focus ~ .select_arrow {
    border-color: global.$primary-color;
    background: global.$off-white;
}
.select select:disabled ~ .select_arrow {
    border-top-color: #cccccc;
}
