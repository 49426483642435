@use "../../scss-fragments/global";

.quizzes-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    /* .add-btn {
        position: absolute;
        left: 2.4rem;
        top: 1.5rem;
        z-index: 1000;
    
        display: flex;
        align-items: center;
        justify-content: center;
    
        width: 2rem;
        height: 2rem;
        border-radius:100em;
        border-width: 2px;
        border-style: solid;
        border-color: global.$twitch-purple;
        color: global.$twitch-purple;
    
        cursor: pointer;
    } */
}

.quizzes-search {
    .search-box {
        min-width: 22rem;
        max-width: 22rem;
    }
}

.change-user-panel-content {
    .submit-btn {
        margin-top: 1rem !important;
        margin-bottom: 0 !important;
    }
}

.quizzes-panel {
    @include global.panel-style;
    @include global.panel-shadow;

    position: relative;

    width: 100%;
    max-height: calc(100% - 7rem);
    flex-grow: 1;

    color: global.$label-color;
    font-weight: 500;

    margin-right:0.5rem !important;
    margin-top: 0 !important;

    .add-btn {
        position: absolute;
        right: 4.2rem !important;
        top: 1.5rem;
        z-index: 1000;
    
        display: flex;
        align-items: center;
        justify-content: center;
    
        width: 2rem;
        height: 2rem;
        border-radius:100em;
        border-width: 2px;
        border-style: solid;
        border-color: global.$twitch-purple;
        color: global.$twitch-purple;
    
        cursor: pointer;
    }

    .scroll-container {
        width: 100%;
        height: calc(100vh - 30rem) !important;
        max-height: calc(100vh - 30rem) !important;
        overflow-y: scroll;

        &::-webkit-scrollbar-track {
            margin-top: 3rem;
        }
    }

    & a {
        color: global.$primary-color;

        &:visited {
            color: global.$primary-color;
        }

        &:hover {
            color: global.$primary-color;
        }

        &:active {
            color: global.$primary-color;
        }
    }

    .grid {
        display: grid;

        grid-template-columns: 23% 13% 10% 10% 5% 18% 15% auto /* repeat(4, auto) */;
        //gap: 0.5rem;
        padding: 0.5rem;

        align-items: center;

        .header {
            @include global.unselectable;

            position: sticky;
            top: 0;
            background-color: global.$panel-body-bg;
            padding-bottom: 1rem;
            width: 100%;
            z-index: 999;
            margin-left:0.5rem;
            margin-top: -0.5rem;
        }

        .pp-style {
            border-color: global.$panel-body-bg;
        }

        .icons {
            color: global.$primary-color;
            margin: 0.2rem 0.75rem;
            cursor: pointer;
        }

        hr {
            position: absolute;
            left: 1.5rem;
            top: 3rem;
            width: calc(100% - 4.5rem);
            z-index: 1000;
        }

        .full-width {
            grid-column-start: 1;
            grid-column-end: 9;
        }

        .centered {
            width: 100%;
            justify-self: center;
            text-align: center;
            margin-left: 0;
        }
    }

    .grid-small {
        grid-template-columns: repeat(6, auto);
    }

    .grid-item {
        padding: 0.5rem;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .grid-item.row-draft-status-colour {
        background-color: global.$warning-light-color;
    }
    .grid-item.row-disabled-status-colour {
        background-color: global.$mid-grey-2;
    }
    .grid-item.row-live-status-colour {
        background-color: global.$success-bg-color;
    }

    .grid-item-long {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }


    .draft-status-colour {
        .drop-down-list {
            color: global.$primary-color;
            background-color: global.$warning-light-color;
        }
        .top-row {
            color: global.$primary-color;
            background-color: global.$warning-light-color;
        }
    }
    .disabled-status-colour {
        .drop-down-list {
            color: global.$primary-color;
            background-color: global.$mid-grey-2;
        }
        .top-row {
            color: global.$primary-color;
            background-color: global.$mid-grey-2;
        }
    }
    .live-status-colour {
        .drop-down-list {
            color: global.$primary-color;
            background-color: global.$success-bg-color;
        }
        .top-row {
            color: global.$primary-color;
            background-color: global.$success-bg-color;
        }
    }
    
    
}

.quiz-type-pill-color {
    color: white;
    background-image: linear-gradient(to bottom right, #8A46FF, #FBB4FF);
}

.poll-type-pill-color {
    color: white;
    background-image: linear-gradient(to bottom right, #1F6CFF, #04F0FB);
}

.tugofwar-type-pill-color {
    color: white;
    background-image: linear-gradient(to bottom right, #FFA946, #FFFB04);
}

.button {
    cursor:pointer;
}
