@use '../../../scss-fragments/global';

.wiz-panel {
    max-width: 50rem;
    width: fit-content;
    min-width: 40rem;
}

.wiz {
    display: flex;
    
    min-height: 24rem;
    width: 100%;
    padding: 1rem 2rem;

    font-weight: 500;
    text-align: center;
    align-items: center;
    justify-content: center;

    .fl-column {
        flex-grow: 1;
    }

    .space {
        margin: 1.2rem 0;
    }

    .big {
        font-size: 1.2rem;
    }

    .em {
        font-style: italic;
    }

    .wiz-step {
        min-height: 53rem;
    }

    button {
        margin: 1rem 0 0;
    }

    a {
        text-decoration: none;
    }

    .cancel-button {
        margin: 1rem 0;
        color: global.$light-text-color;
        cursor: pointer;
    }

    .mid-button {
        margin: 1rem 0;
    }

    h2 {
        margin: 0
    }

    .status-dot {
        margin-right: 0.5rem;
    }

    .active {
        color: global.$active-color;
    }

    .inactive {
        color: global.$error-dark-color;
    }

    .steps {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 2rem;

        .step {
            display: flex;
            min-width: 3.7rem;
            width: 3.7rem;
            height: 3.7rem;
            font-size: 1.8rem;
            font-weight: 600;
            color: global.$mid-grey-2;
            border: solid 2px global.$mid-grey-2;
            border-radius: 100em;
            text-align: center;
            align-items: center;
            justify-content: center;
            margin: 0 0.2rem;
        }

        .current {
            color: global.$primary-color;
            border: solid 2px global.$primary-color;
        }

        .completed {
            color: #fff;
            border: solid 2px global.$success-color;
            background-color: global.$success-color;
            cursor: pointer;
        }

        .divider {
            min-width: 1.8rem;

            display: flex;
            height: 2rem;

            background-image: url(../../../assets/wiz-divider.svg);
            background-repeat: repeat-x;
            background-position: center;
            background-size: 24px 2px;
        }
    }

    .status-lozenge {
        min-width: 20rem;
        border: solid 0px #000;
        border-radius: 50em;
        font-size: 0.9rem;
        letter-spacing: 0.1rem;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
        padding: 0.3rem 1rem;
    }

    .off {
        background-color: global.$error-dark-color;
    }

    .on {
        background-color: global.$success-dark-color;
    }

    .wh-btn {
        background-color: #FFF;
        color: global.$primary-color;
        font-weight: 600;
    }

    .final-warning {
        img {
            width: 32px;
            height: 32px;
            margin-bottom: 1rem;
        }

        color: global.$error-dark-color;
    }
}
