@use '../../scss-fragments/global';

.tr-item {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;

    white-space: nowrap;
    text-overflow: ellipsis;

    cursor: default;

    .tr-pill {
        @include global.pill-style;
        margin: 0;

        font-weight: 700;
        justify-content: center;
        align-content: center;

        min-width: 8rem;
        max-width: 8rem;
        width: 8rem;

        text-transform: uppercase;

        p {
            margin: 0 0.5rem;
        }
    }

    .tr-time {
        font-weight: 500;
        color: global.$light-text-color;
    }

    .tr-output {
        font-weight: 600;
    }

    .default-tr-color {
        color: #FFF;
        border-color: global.$default-tr-color;
        background-color: global.$default-tr-color;
    }

    .disrupter-tr-color {
        color: #FFF;
        border-color: global.$disrupter-tr-color;
        background-color: global.$disrupter-tr-color;
    }

    .feature-tr-color {
        color: #FFF;
        border-color: #4DBEA6;
        background-color: #4DBEA6;
    }

    .bot-tr-color {
        color: #FFF;
        border-color: global.$bot-tr-color;
        background-color: global.$bot-tr-color;
    }

    .disrupter-audio-tr-color {
        color: #FFF;
        border-color: global.$disrupter-tr-color;
        background-color: global.$disrupter-tr-color;
    }

    .disrupter-image-overlay-tr-color {
        color: #FFF;
        border-color: global.$disrupter-tr-color;
        background-color: global.$disrupter-tr-color;
    }

    .disrupter-animated-overlay-tr-color {
        color: #FFF;
        border-color: global.$disrupter-tr-color;
        background-color: global.$disrupter-tr-color;
    }

    .disrupter-video-tr-color {
        color: #FFF;
        border-color: global.$disrupter-tr-color;
        background-color: global.$disrupter-tr-color;
    }
}
