@use "../../scss-fragments/global";

.settings-pop-canceller {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0rem;
    z-index: 999;
}

.settings-pop-container {
    @include global.button-style;
    @include global.panel-shadow;
    @include global.standard-rollover-transition;

    display: block;
    position: fixed;
    right: 0rem;
    top: 4rem;
    z-index: 1000;

    text-align: left;

    width: 18rem;
    margin: 0.5rem;
    padding: 0.5rem;

    background-color: global.$dark-grey-2;
    border-color: global.$dark-grey-2;
    color: global.$mid-grey-1;

    transform: scale(1);
    transform-origin: 100% 0;

    button {
        @include global.button-style;

        margin: auto;
        width: 100%;
        height: 3rem;

        background-color: global.$dark-grey-2;
        color: global.$mid-grey-1;
        border-color: global.$mid-grey-1;

        transition: all 0.1s;

        &:hover {
            color: global.$light-grey-1;
            background-color: global.$primary-color;
            border-color: global.$primary-color;
        }
    }

    a {
        text-decoration: none;
    }

    .profile {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
            display: inline-block;
            text-align: left;
            color: global.$off-white;
            margin: 0;
            line-height: 1.2rem;
        }
    
        .pp-style {
            border-color: global.$dark-grey-2;
            margin: 0 0.75rem 0 0;
        }

        .text {
            display: flex;
            flex-grow: 1;
        }
    }
}

.settings-pop-hidden {
    transform: scale(0);
}
