@use "../../scss-fragments/global";
@use "../../scss-fragments/forms";

.select-user-pop {
    .scroll-panel {
        border-radius: 2rem;
        border-width: 2px;
        border-style: solid;
        margin: 1rem 0;
        padding: 1.5rem;
        border-color: #FFF;
        background-color: #FFF;
        width: 100%;
        height: 100%;
        min-height: calc(100vh - 35rem);
        max-height: calc(100vh - 35rem);
        background-color: #F7F7FC;

        p {
            margin-bottom: 1rem;
        }
    }

    .one-user {
        @include global.standard-rollover-transition;
        cursor: pointer;
    }

    .one-user:hover {
        background-color: global.$primary-light-color;
    }
}